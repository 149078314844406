import * as Sentry from "@sentry/vue";

const supplied_data_url = `https://${import.meta.env.VITE_APP_SUPPLIER_BUCKET}.s3.${
  import.meta.env.VITE_APP_SUPPLIER_BUCKET_REGION
}.amazonaws.com`;

const releases_url = `https://${import.meta.env.VITE_APP_RELEASES_BUCKET}.s3.${
  import.meta.env.VITE_APP_REGION
}.amazonaws.com`;

export default {
  install: (app, options) => {
    Sentry.init({
      dsn: import.meta.env.VITE_APP_SENTRY_DSN,
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration({
          maskAllText: false,
          blockAllMedia: false,
        }),
      ],
      // Performance Monitoring
      tracesSampleRate: 1.0, //  Capture 100% of the transactions
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [
        "localhost",
        /^https:\/\/cognito-idp.*.amazonaws.com/,
        /^https:\/\/cognito-identity.*.amazonaws.com/,
        /^https:\/\/dynamodb.*.amazonaws.com/,
        new RegExp("^" + import.meta.env.VITE_APP_CLOUDFRONT_BASE_URL),
        new RegExp("^" + supplied_data_url),
        new RegExp("^" + releases_url),
      ],
      // Session Replay
      replaysSessionSampleRate: 0, // Disable session replay when there are no errors
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
  },
};
