function formatSizeFromBytes(size_in_bytes, to_bit = false) {
  var size = to_bit ? size_in_bytes * 8 : size_in_bytes;
  var unit = to_bit ? "b" : "B";
  if (size_in_bytes > 1e8) {
    return `${(size / 1e9).toFixed(1)} G${unit}`;
  } else if (size_in_bytes > 1e5) {
    return `${(size / 1e6).toFixed(1)} M${unit}`;
  } else {
    return `${(size / 1e3).toFixed(1)} K${unit}`;
  }
}

function formatSizeFromBytesWithoutUnits(size_in_bytes, reference_size) {
  if (reference_size > 1e8) {
    return `${(size_in_bytes / 1e9).toFixed(1)}`;
  } else if (reference_size > 1e5) {
    return `${(size_in_bytes / 1e6).toFixed(1)}`;
  } else {
    return `${(size_in_bytes / 1e3).toFixed(1)}`;
  }
}

function formatTimeFromSeconds(seconds) {
  let hours = Math.floor(seconds / 3600);
  seconds %= 3600;
  let minutes = Math.floor(seconds / 60);
  if (hours > 0) {
    return minutes > 0 ? `${hours}h ${minutes}m` : `${hours}h`;
  }
  seconds %= 60;
  seconds = Math.ceil(seconds);
  return minutes > 0 ? `${minutes}m ${seconds}s` : `${seconds}s`;
}

function getUsernameFromFolderName(folderName) {
  folderName = folderName ? folderName : "";
  // folderName = "eu-central-1:90a2948e-dca1-47f3-8daf-f8e50e67c02d"
  const regionPrefix = import.meta.env.VITE_APP_SUPPLIER_BUCKET_REGION + ":";
  return folderName.startsWith(regionPrefix) ? folderName.slice(regionPrefix.length) : folderName;
}

function joinPath(...args) {
  return args
    .map((part, i) => {
      if (i === 0) {
        return part.trim().replace(/[\/]*$/g, "");
      } else {
        return part.trim().replace(/(^[\/]*|[\/]*$)/g, "");
      }
    })
    .filter((x) => x.length)
    .join("/");
}

export {
  formatSizeFromBytes,
  formatSizeFromBytesWithoutUnits,
  joinPath,
  getUsernameFromFolderName,
  formatTimeFromSeconds,
};
