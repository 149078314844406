import { defineStore } from "pinia";
import { ref } from "vue";
import { Auth } from "aws-amplify";
import { DynamoDBClient, GetItemCommand, UpdateItemCommand, BatchGetItemCommand } from "@aws-sdk/client-dynamodb";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);

import { formatDate } from "@/utils/date";

export const useUserStore = defineStore(
  "user",
  () => {
    function getGuest() {
      return {
        authStatus: "guest",
        username: null,
        attributes: {
          email: null,
          email_verified: null,
          tenant: null,
        },
        impersonate: {
          username: null,
        },
      };
    }

    const user = ref(getGuest());
    const auth_token = ref(null);

    function $reset() {
      user.value = getGuest();
    }

    function setToken(token) {
      auth_token.value = token;
    }

    function fromCognito(user_info) {
      user.value = {
        authStatus: "authenticated",
        username: user_info.username,
        attributes: {
          email: user_info.attributes.email,
          email_verified: user_info.attributes.email_verified,
          tenant: user_info.attributes["custom:tenant"] || null,
        },
        impersonate: {
          username: user_info.username,
        },
      };
    }

    function impersonate(impersonateUser) {
      console.log("impersonateUser:", impersonateUser);
      user.value.impersonate = impersonateUser;
    }

    function exitImpersonate() {
      user.value.impersonate = {
        username: user.value.username,
      };
    }

    async function getAuthorizedReleases(username = null) {
      console.log("Fetching authorized releases");
      const dynamodb_client = new DynamoDBClient({
        region: import.meta.env.VITE_APP_REGION,
        credentials: Auth.currentCredentials,
      });
      const dynamodb_get_command_input = {
        Key: {
          cognitoId: {
            S: username ?? user.value.username,
          },
        },
        TableName: import.meta.env.VITE_APP_DYNAMODB_USER_TABLE,
      };
      const dynamodb_get_command = new GetItemCommand(dynamodb_get_command_input);
      const dynamodb_response = await dynamodb_client.send(dynamodb_get_command);

      const releases_authorized_map = dynamodb_response?.Item?.Releases?.M;
      /* releases_authorized_map = {
       *   "2021-10-26_bergkirchen_627ceeae-585e-11ee-8a54-b40edefbfc98": {"BOOL":true},
       *   "2021-06-25_munich_635a2026-585e-11ee-8a54-b40edefbfc98": {"BOOL":true}
       * } */

      let releases_authorized_array = [];
      if (releases_authorized_map) {
        for (const [key, value] of Object.entries(releases_authorized_map)) {
          if (value?.BOOL) {
            releases_authorized_array.push(key);
          }
        }
      }

      let researcher_flag = !!dynamodb_response?.Item?.Researcher?.BOOL;

      return [releases_authorized_array, researcher_flag];
    }

    async function getAuthorizedReleasesBatch(usernames = []) {
      console.log("Fetching authorized releases for multiple users");

      if (!usernames.length) {
        return {};
      }

      const dynamodb_client = new DynamoDBClient({
        region: import.meta.env.VITE_APP_REGION,
        credentials: Auth.currentCredentials,
      });

      const keys = usernames.map((username) => ({
        cognitoId: { S: username },
      }));

      const dynamodb_batch_get_command_input = {
        RequestItems: {
          [import.meta.env.VITE_APP_DYNAMODB_USER_TABLE]: {
            Keys: keys,
          },
        },
      };

      const dynamodb_batch_get_command = new BatchGetItemCommand(dynamodb_batch_get_command_input);
      const dynamodb_response = await dynamodb_client.send(dynamodb_batch_get_command);

      const results = {};
      const responses = dynamodb_response.Responses[import.meta.env.VITE_APP_DYNAMODB_USER_TABLE];

      for (const item of responses) {
        const username = item.cognitoId.S;
        const releases_authorized_map = item.Releases?.M;

        let releases_authorized_array = [];
        if (releases_authorized_map) {
          for (const [key, value] of Object.entries(releases_authorized_map)) {
            if (value?.BOOL) {
              releases_authorized_array.push(key);
            }
          }
        }

        const researcher_flag = !!item.Researcher?.BOOL;
        results[username] = { releases: releases_authorized_array, researcher: researcher_flag };
      }

      return results;
    }

    async function getLastActivity(username = null) {
      console.log("Fetching last_activity");
      const dynamodb_client = new DynamoDBClient({
        region: import.meta.env.VITE_APP_REGION,
        credentials: Auth.currentCredentials,
      });
      const dynamodb_get_command_input = {
        Key: {
          cognitoId: {
            S: username ?? user.value.username,
          },
        },
        TableName: import.meta.env.VITE_APP_DYNAMODB_USER_SETTINGS_TABLE,
      };
      const dynamodb_get_command = new GetItemCommand(dynamodb_get_command_input);
      const dynamodb_response = await dynamodb_client.send(dynamodb_get_command);

      console.log("Got last_activity");
      const last_activity = dynamodb_response?.Item?.LastActivity?.S;
      /* releases_authorized_map = {
       *   "2021-10-26_bergkirchen_627ceeae-585e-11ee-8a54-b40edefbfc98": {"BOOL":true},
       *   "2021-06-25_munich_635a2026-585e-11ee-8a54-b40edefbfc98": {"BOOL":true}
       * } */
      if (!last_activity || last_activity === "Never") {
        return "Never";
      }
      return formatDate(last_activity, "DD MMM YYYY");
    }

    async function getLastAcceptedPrivacyPolicy(username = null) {
      const dynamodb_client = new DynamoDBClient({
        region: import.meta.env.VITE_APP_REGION,
        credentials: Auth.currentCredentials,
      });

      const dynamodb_get_command_input = {
        Key: {
          cognitoId: {
            S: username ?? user.value.username,
          },
        },
        TableName: import.meta.env.VITE_APP_DYNAMODB_USER_SETTINGS_TABLE,
      };
      const dynamodb_get_command = new GetItemCommand(dynamodb_get_command_input);
      const dynamodb_response = await dynamodb_client.send(dynamodb_get_command);

      const last_accepted_privacy_policy_date = dynamodb_response?.Item?.LastAcceptedPrivacyPolicy?.S;
      if (last_accepted_privacy_policy_date == null) {
        return undefined;
      }

      return dayjs(last_accepted_privacy_policy_date).utc().toDate();
    }

    async function updateLastAcceptedPrivacyPolicy(new_accepted_date, username = null) {
      if (username === null) {
        username = user.value.username;
      }
      try {
        const dynamodb_client = new DynamoDBClient({
          region: import.meta.env.VITE_APP_REGION,
          credentials: Auth.currentCredentials,
        });

        const dynamodb_get_activity_command_input = {
          Key: {
            cognitoId: {
              S: username,
            },
          },
          UpdateExpression: "SET LastAcceptedPrivacyPolicy = :last_privacy_policy",
          ExpressionAttributeValues: {
            ":last_privacy_policy": {
              S: new_accepted_date.toISOString(),
            },
          },
          TableName: import.meta.env.VITE_APP_DYNAMODB_USER_SETTINGS_TABLE,
        };

        const dynamodb_update_policy_command = new UpdateItemCommand(dynamodb_get_activity_command_input);
        const dynamodb_update_response = await dynamodb_client.send(dynamodb_update_policy_command);
      } catch (e) {
        console.error("Error updating policy privacy", e);
      }
    }

    async function getUserSettingsBatch(usernames = []) {
      console.log("Fetching last activities for multiple users");

      if (!usernames.length) {
        return {};
      }

      const dynamodb_client = new DynamoDBClient({
        region: import.meta.env.VITE_APP_REGION,
        credentials: Auth.currentCredentials,
      });

      const keys = usernames.map((username) => ({
        cognitoId: { S: username },
      }));

      const dynamodb_batch_get_command_input = {
        RequestItems: {
          [import.meta.env.VITE_APP_DYNAMODB_USER_SETTINGS_TABLE]: {
            Keys: keys,
          },
        },
      };

      const dynamodb_batch_get_command = new BatchGetItemCommand(dynamodb_batch_get_command_input);
      const dynamodb_response = await dynamodb_client.send(dynamodb_batch_get_command);

      const results = {};
      const responses = dynamodb_response.Responses[import.meta.env.VITE_APP_DYNAMODB_USER_SETTINGS_TABLE];

      for (const item of responses) {
        const username = item.cognitoId.S;
        const last_activity = item.LastActivity?.S;
        const accepted_privacy_policy = item.LastAcceptedPrivacyPolicy?.S;

        results[username] = {};

        results[username].last_activity =
          last_activity && last_activity !== "Never" ? formatDate(last_activity, "DD MMM YYYY") : "Never";
        results[username].accepted_privacy_policy =
          accepted_privacy_policy && accepted_privacy_policy !== "Never"
            ? formatDate(accepted_privacy_policy, "DD MMM YYYY")
            : "Never";
      }
      return results;
    }

    async function retrieveCurrentSession() {
      try {
        const session = await Auth.currentSession();
        if (auth_token.value !== session.getAccessToken().getJwtToken())
          setToken(session.getAccessToken().getJwtToken());

        const dynamodb_client = new DynamoDBClient({
          region: import.meta.env.VITE_APP_REGION,
          credentials: Auth.currentCredentials,
        });

        const dynamodb_get_activity_command_input = {
          Key: {
            cognitoId: {
              S: user.value.username,
            },
          },
          UpdateExpression: "SET LastActivity = :last_activity",
          ExpressionAttributeValues: {
            ":last_activity": {
              S: dayjs(),
            },
          },
          TableName: import.meta.env.VITE_APP_DYNAMODB_USER_SETTINGS_TABLE,
        };
        const dynamodb_get_activity_command = new UpdateItemCommand(dynamodb_get_activity_command_input);
        const dynamodb_activity_response = await dynamodb_client.send(dynamodb_get_activity_command);
      } catch (e) {
        console.error("Error retrieving current session", e);
      }
    }

    return {
      user,
      auth_token,
      $reset,
      fromCognito,
      setToken,
      impersonate,
      exitImpersonate,
      getAuthorizedReleases,
      getAuthorizedReleasesBatch,
      retrieveCurrentSession,
      getLastActivity,
      getUserSettingsBatch,
      getLastAcceptedPrivacyPolicy,
      updateLastAcceptedPrivacyPolicy,
    };
  },
  {
    storage: localStorage,
  }
);
