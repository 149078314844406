const not_defined: string = "Undefined";

function shapeToString(shape: [number, number] | undefined): string {
  if (shape) {
    return `${shape[0]}x${shape[1]}`;
  } else {
    return not_defined;
  }
}

function shapeFromString(string: string): number[] {
  return string.split("x").map(Number);
}

function frameRateToString(frame_rate: number | undefined): string {
  if (frame_rate) {
    return `${frame_rate}Hz`;
  } else {
    return not_defined;
  }
}

type DisplayMinimumType = "all" | "seconds" | "minutes" | "hours";
type UnitsFormatType = "long" | "short";

function durationToString(
  total_seconds: number,
  displayMinimum: DisplayMinimumType = "all",
  unitsFormat: UnitsFormatType = "long"
): string {
  const hours = Math.floor(total_seconds / 3600);
  const minutes = Math.floor((total_seconds % 3600) / 60);
  const seconds = Math.floor(total_seconds % 60);

  const units: Record<string, Record<UnitsFormatType, string>> = {
    s: {
      long: "seconds",
      short: "s",
    },
    m: {
      long: "minutes",
      short: "m",
    },
    h: {
      long: "hours",
      short: "h",
    },
  };

  let text = "";

  if (displayMinimum === "seconds" || displayMinimum === "all") {
    text = `${seconds} ${units["s"][unitsFormat]}`;
  }

  if (displayMinimum === "minutes" || displayMinimum === "seconds" || displayMinimum === "all") {
    if (minutes > 0) {
      text = `${minutes} ${units["m"][unitsFormat]} ${text}`;
    }
  }

  if (
    displayMinimum === "hours" ||
    displayMinimum === "minutes" ||
    displayMinimum === "seconds" ||
    displayMinimum === "all"
  ) {
    if (hours > 0) {
      text = `${hours} ${units["h"][unitsFormat]} ${text}`;
    }
  }

  return text;
}

type StatusType = "waiting" | "checking" | "error" | string;

function getIconWithStatus(status: StatusType): string {
  switch (status) {
    case "waiting":
      return "mdi-cloud-upload";
    case "checking":
      return "mdi-timer-sand";
    case "error":
      return "mdi-alert-circle";
    default:
      return "mdi-cloud-upload";
  }
}

export function formatPrice(value: number): string {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " €";
}

const formatDuration = (time_passed_secs: number): string => {
  if (isNaN(time_passed_secs)) return "0:00";

  const minutes = Math.floor(time_passed_secs / 60);
  const seconds = Math.floor(time_passed_secs % 60);

  const formatted_seconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
  return `${minutes}:${formatted_seconds}`;
};

export function makeSlugFromReleaseAlias(release_alias: string): string {
  return release_alias.toLowerCase().split(" ").join("-");
}

export { shapeToString, shapeFromString, frameRateToString, durationToString, getIconWithStatus, formatDuration };
