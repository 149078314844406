import { useUserStore } from "@/stores/userStore";

const aclRules = {
  "role-guest": (user) => {
    return user?.authStatus === "guest";
  },
  "role-user": (user) => {
    return user?.authStatus === "authenticated";
  },
  "role-supplier": (user) => {
    let tenant = user?.attributes?.tenant || "";
    // ylorenz todo with tenant or maybe attribute "default space"
    return (tenant && (tenant.includes("supplier") || tenant.includes("admin"))) || false;
  },
  "role-explorer": (user) => {
    let tenant = user?.attributes?.tenant || "";
    // ylorenz todo with tenant or maybe attribute "default space"
    return (tenant && (tenant.includes("explorer") || tenant.includes("admin"))) || false;
  },
  "role-admin": (user) => {
    let tenant = user?.attributes?.tenant;
    return tenant && tenant.includes("admin");
  },
};

const can = function (rule = null) {
  //console.log("can", rule);
  if (!rule) {
    return false;
  }

  const userStore = useUserStore();
  if (aclRules?.[rule]) {
    return aclRules[rule](userStore.user);
  } else {
    console.log(rule + " missing in aclRules");
    return false;
  }
};

export { aclRules, can };
