<script setup>
import { onMounted, onUnmounted, toRefs } from "vue";
import { I18n } from "aws-amplify";
import { translations } from "@aws-amplify/ui-vue";

import { Authenticator, useAuthenticator } from "@aws-amplify/ui-vue";
import { Hub, Auth } from "aws-amplify";
import { event as gtag } from "vue-gtag";

import { useUserStore } from "@/stores/userStore";
import { can } from "@/utils/aclRules";
import { goToRoute } from "@/utils/nav";
import dayjs from "dayjs";

const userStore = useUserStore();
const auth = useAuthenticator();

const { toSignIn } = toRefs(auth);

const hub_listen_auth_cancel_function = Hub.listen("auth", async ({ payload }) => {
  const { event, data } = payload;
  console.log("Hub.listen auth event: ", event);
  if (event === "signIn" || event === "autoSignIn") {
    /* data is user_info unless first-time log-in */
    const user_info = data?.attributes ? data : await Auth.currentUserInfo();
    console.log("Hub.listen auth user_info: ", user_info);
    userStore.fromCognito(user_info);
    userStore.setToken(payload.data.signInUserSession.accessToken.jwtToken);
    userStore.retrieveCurrentSession();

    // In case the user did not accepted the privacy policy yet (coming from registration e-mail), we mark it as accepted
    const last_accepted_policy = await userStore.getLastAcceptedPrivacyPolicy();
    if (last_accepted_policy === undefined) {
      const date_now = dayjs().utc();
      userStore.updateLastAcceptedPrivacyPolicy(date_now);
    }

    gtag("login_successful", { event_category: "Login", value: 1 });

    const url_params = new URLSearchParams(window.location.search);
    const redirect_url = url_params.get("redirect_url");
    if (redirect_url) {
      window.location.href = redirect_url;
      return;
    }

    if (can("role-explorer") || can("role-admin")) {
      goToRoute({
        name: "explore-release-list",
      });
    } else if (can("role-supplier")) {
      goToRoute({
        name: "supply-project-list",
      });
    }
  } else if (event === "autoSignIn_failure" || event === "signIn_failure") {
    // redirect to sign in page
    gtag("login_failed", { event_category: "Login", value: 2 });
  }
});

const loginFormFields = {
  signIn: {
    username: {
      placeholder: "E-mail",
      isRequired: true,
      labelHidden: true,
    },
    password: {
      placeholder: "Password",
      isRequired: true,
      labelHidden: true,
    },
  },
  resetPassword: {
    username: {
      placeholder: "E-mail",
      isRequired: true,
      labelHidden: true,
    },
  },
  forceNewPassword: {
    password: {
      placeholder: "New password",
      isRequired: true,
      labelHidden: true,
    },
    confirm_password: {
      placeholder: "Confirm new password",
      isRequired: true,
      labelHidden: true,
    },
  },
  confirmResetPassword: {
    confirmation_code: {
      placeholder: "Code",
      isRequired: true,
      labelHidden: true,
    },
    password: {
      placeholder: "New password",
      isRequired: true,
      labelHidden: true,
    },
    confirm_password: {
      placeholder: "Confirm new password",
      isRequired: true,
      labelHidden: true,
    },
  },
};

I18n.putVocabularies(translations);
I18n.setLanguage("en");

I18n.putVocabularies({
  en: {
    "Signing in": "Sign In",
    "Change Password": "Continue",
    "Back to Sign In": "Exit",
  },
});

const carousel_images = [
  new URL("@/assets/ScenariosRatio54.jpg", import.meta.url).href,
  new URL("@/assets/CameraRatio54.jpg", import.meta.url).href,
  new URL("@/assets/WorldViewRatio54.png", import.meta.url).href,
];

onUnmounted(() => {
  hub_listen_auth_cancel_function();
});

onMounted(() => {
  Auth.currentAuthenticatedUser()
    .then(async () => {
      try {
        const user_info = await Auth.currentUserInfo();
        userStore.fromCognito(user_info);
        goToRoute({
          name: "supply-project-list",
        });
      } catch (error) {
        console.log(`Something went wrong while retrieving user info. Logging out.`);
        userStore.$reset();
        await Auth.signOut();
      }
    })
    .catch(() => {
      userStore.$reset();
      Auth.signOut();
    });
});

const registerUser = () => {
  window.open("https://www.deepscenario.com/register", "_blank");
};
</script>

<template>
  <v-container id="login-container" class="full-height login-view d-flex flex-column flex-nowrap">
    <v-row id="header" justify="space-between" no-gutters>
      <v-img
        class="logo"
        alt="DeepScenario"
        max-height="40"
        max-width="220"
        src="@/assets/logo-darkviolet.svg"
        @click="toSignIn"
      />
    </v-row>
    <v-row id="login-carousel-and-form-row" no-gutters>
      <v-col class="d-flex mt-6">
        <v-row class="login-form-container">
          <div :class="{ 'validation-errors': auth.error, loading: auth.isPending }">
            <authenticator :hide-sign-up="true" :form-fields="loginFormFields">
              <template v-slot:sign-in-header>
                <div class="pa-0 heading-container">
                  <h1 class="amplify-heading">
                    <div>
                      <span>Sign in </span>
                      <span class="heading-highlight">to</span>
                    </div>
                    <span class="heading-highlight">your account</span>
                  </h1>
                </div>
                <div class="registration">
                  <p class="pr-2">Not registered yet?</p>
                  <a
                    @click="registerUser"
                    class="amplify-button amplify-button--link amplify-field-group__control"
                    data-fullwidth="false"
                    data-size="small"
                    data-variation="link"
                    type="button"
                    id="request-access"
                  >
                    Request Access
                  </a>
                </div>
              </template>

              <!-- Reset password templates -->
              <template v-slot:reset-password-header>
                <div class="pa-0 heading-container reset-password">
                  <h1 class="amplify-heading">
                    <div>
                      <span>Reset </span>
                      <span class="heading-highlight">password</span>
                    </div>
                  </h1>
                </div>
              </template>
              <template v-slot:confirm-reset-password-header>
                <div class="pa-0 heading-container reset-password">
                  <h1 class="amplify-heading">
                    <div>
                      <span>Reset </span>
                      <span class="heading-highlight">password</span>
                    </div>
                  </h1>
                </div>
              </template>
              <template v-slot:sign-in-footer>
                <div style="text-align: center">
                  <button
                    @click="auth.toResetPassword"
                    class="amplify-button amplify-button--link amplify-field-group__control"
                    data-fullwidth="false"
                    data-size="small"
                    data-variation="link"
                    type="button"
                  >
                    Forgot Password?
                  </button>
                </div>
              </template>

              <template v-slot:force-new-password-header>
                <div class="pa-0 heading-container reset-password">
                  <h1 class="amplify-heading">
                    <div>
                      <span>Finish </span>
                      <span class="heading-highlight">registration</span>
                    </div>
                  </h1>
                </div>
              </template>
              <template v-slot:force-new-password-footer>
                <div class="privacy-policy-text">
                  <p class="pr-2">By completing your registration, you agree to our</p>
                  <a href="https://www.deepscenario.com/data-privacy" target="_blank" class="privacy-policy-link"
                    >Privacy Policy</a
                  >
                </div>
              </template>
            </authenticator>
          </div>
        </v-row>
      </v-col>
      <v-col class="carousel-container mt-6">
        <v-carousel
          hide-delimiters
          cycle
          touch
          :touchless="true"
          interval="5000"
          hide-delimiter-background
          :show-arrows="false"
          class="carousel"
        >
          <v-carousel-item :src="carousel_images[0]" cover></v-carousel-item>

          <v-carousel-item :src="carousel_images[1]" cover></v-carousel-item>

          <v-carousel-item :src="carousel_images[2]" cover></v-carousel-item>
        </v-carousel>
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped>
@media screen and (max-width: 930px) {
  /* break into single column, align on center */
  #login-carousel-and-form-row {
    flex-direction: column !important;
  }

  .login-form-container {
    justify-content: center !important;
  }

  .carousel-container {
    justify-content: center !important;
  }
}

#login-carousel-and-form-row {
  padding-top: 1rem;
}

.login-form-container {
  align-items: center;
}

#login-container {
  max-width: 1200px !important;
  justify-content: center;
}

#header {
  max-height: 40px;
}

.validation-errors:deep(* .amplify-input) {
  border-color: #f05886;
}

.validation-errors:deep(.amplify-button.amplify-field__show-password) {
  border-color: #f05886;
}

.loading:deep(* [data-amplify-router-content] footer) {
  padding-left: 190px;
}

.login-view:deep([data-amplify-router-content] footer.amplify-authenticator__column) {
  flex-direction: row;
  align-items: center;
  padding-left: 0 !important;
  margin-top: 0;
}

.login-view:deep([data-amplify-router-content]) {
  padding-bottom: 1rem;
}

.login-view:deep(footer > .amplify-button--primary) {
  color: #fff;
  margin-top: 0;
}

.login-view:deep(footer > .amplify-button--link) {
  height: fit-content;
}

.login-view:deep(.amplify-button) {
  background: #281868;
}

.login-view:deep(.amplify-button:hover) {
  background: #6d4bf2;
}

.login-view:deep(.amplify-input:focus) {
  box-shadow: none !important;
}

.login-view:deep(.amplify-field-group:focus-within > .amplify-field-group__outer-end > .amplify-button),
.login-view:deep(.amplify-field-group:focus-within > .amplify-field-group__field-wrapper > .amplify-input) {
  border-color: #141414 !important;
}

.login-view:deep(.amplify-text--error),
.login-view:deep(.amplify-alert--error) {
  color: #f05886;
  background-color: rgb(var(--v-theme-ds-primary-grey));
  position: absolute;

  font-family: Manrope;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  padding: 0 18px;
  margin-top: 162px;
}

.login-view:deep(.amplify-text--error) {
  margin-top: -20px;
}

.login-view:deep([data-amplify-router-content] footer) {
  margin-top: -46px;
  padding-left: 160px;
  width: fit-content;
}

.login-view:deep(
    footer
      .amplify-button.amplify-button--link.amplify-button--small.amplify-field-group__control.amplify-authenticator__font
  ) {
  margin-left: 14px;
}

.login-view:deep(.amplify-button[data-variation="link"]) {
  color: #6d4bf2;
  font-family: Manrope;
  font-size: 14px;
  font-style: normal;
  font-weight: 700 !important;
  line-height: 20px;
  letter-spacing: 2.4px;
  text-transform: uppercase;
  text-decoration: none !important;
}

.login-view:deep(.amplify-button[data-variation="link"]:focus) {
  box-shadow: none;
}

.login-view:deep(.amplify-heading) {
  font-family: Manrope;
  font-size: 48px;
  font-style: normal;
  font-weight: 300;
  align-self: stretch;
  background: linear-gradient(95deg, #6f4bf0 0.79%, #d2a7ef 37.64%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.heading-highlight {
  background: #141414;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.login-view:deep(.amplify-alert--error > .amplify-authenticator__icon-wrapper > svg) {
  display: none;
}

.login-view:deep(.amplify-flex) {
  gap: 0;
}

.login-view:deep(.amplify-alert--error > .amplify-authenticator__icon-wrapper > svg) {
  display: none;
}

.login-view:deep(.amplify-field__show-password:hover) {
  background: none;
}

.login-view:deep(.amplify-field__show-password) {
  background: none;
}

.login-view:deep(.amplify-button[data-variation="link"]) {
  background: none;
  text-decoration: underline;
}

.login-view:deep(.amplify-field-group__outer-end) {
  margin-bottom: 1.5rem;
}

.login-view:deep(.amplify-button.amplify-field__show-password) {
  background: url(@/assets/show_password.svg) no-repeat;
  background-position: center;
  border-radius: 0 16px 16px 0;
  border: 1px solid #d0d0d0;
  border-left: none;
  width: 54px;
}

.login-view:deep(.amplify-button.amplify-field__show-password:focus) {
  box-shadow: none;
}

.login-view:deep(.amplify-button.amplify-field__show-password[aria-checked="true"]) {
  background: url(@/assets/hide_password.svg) no-repeat;
  background-position: center;
}

.login-view:deep(.amplify-button > svg) {
  display: none;
}

.login-view:deep(.v-row) {
  margin: 0;
  flex: none !important;
}

.login-view:deep(.amplify-button--primary) {
  display: flex;
  padding: 16px 24px 16px 54px;
  justify-content: center;
  align-items: center;
  gap: 16px;
  border-radius: 40px;
  background: url(@/assets/icon-right.svg) #6d4bf2 no-repeat;
  background-position-x: 16px;
  background-position-y: 50%;
  width: fit-content;
  max-width: 220px;
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 700 !important;
  letter-spacing: 2.8px;
  text-transform: uppercase;
}

/* Selector for the Sign In Button when it is both hovered and disabled (e.g. when using cursor) */
.login-view:deep(.amplify-button--primary:hover:disabled) {
  background: url(@/assets/icon-loading.svg) #573cc2 no-repeat;
  background-position-x: 16px;
  background-position-y: 50%;
}

/* Selector for the Sign In Button when it is disabled (e.g. when using tab to navigate page) */
.login-view:deep(.amplify-button--primary:disabled) {
  background: url(@/assets/icon-loading.svg) #573cc2 no-repeat;
  background-position-x: 16px;
  background-position-y: 50%;
}

/* Selector for the Sign In Button when it is hovered*/
.login-view:deep(.amplify-button--primary:hover) {
  background: url(@/assets/icon-right.svg) #573cc2 no-repeat;
  background-position-x: 16px;
  background-position-y: 50%;
}

.login-view:deep(.amplify-button--primary:focus) {
  box-shadow: none;
}

.login-view:deep([data-amplify-password]) {
  border-radius: 16px 0 0 16px !important;
  border-right: none !important;
  padding: 0 16px;
}

.login-view:deep([data-amplify-container]) {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;
  /* margin-left: 125px; */
  margin-left: 0;
  width: 100% !important;
}

.login-view:deep(.amplify-field-group :not(:first-child) .amplify-input) {
  border-radius: 16px;
}

.login-view:deep([data-amplify-authenticator] [data-amplify-form]) {
  padding: 0;
}

.login-view:deep([data-amplify-authenticator] [data-amplify-router]) {
  width: 406px;
  background-color: inherit;
  border: inherit;
  box-shadow: none;

  @media screen and (max-width: 909px) {
    width: 100% !important;
    padding-left: 0;
  }
}

.login-view:deep(.amplify-input) {
  display: flex;
  height: 68px;
  padding: 0px 24px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 16px;
  border: 1px solid #d0d0d0;
  flex: 1 0 0;
  color: #5b5b5b;
  font-family: Manrope;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 1.5rem;
}

.full-height {
  height: 100%;
}

.login-view:deep(.column) {
  height: 100vh; /* Set a height for the container */
  display: flex; /* Use flexbox for layout */
  flex-direction: column; /* Arrange elements vertically */
}

.login-view:deep(.header-row) {
  height: 40px;
}

.login-view:deep(.shrink) {
  flex-grow: 0;
  flex-shrink: 1;
}

.logo {
  cursor: pointer;
}

.carousel-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex: 1 1 70px;
  display: flex;
}

.carousel {
  max-width: 690px;
  max-height: 650px;
  height: 100% !important;
  aspect-ratio: 5 / 4 !important;
  border-radius: 24px;
}

.login-view:deep(.form-container) {
  display: flex;
  max-width: 720px;
  flex-direction: column;
  justify-content: center;
}

.login-view:deep(.beta-wrapper) {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.beta {
  max-width: 72px;
  text-transform: uppercase;
  border-width: 2px;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: 1.5rem;
  background-color: #ffffff;
  color: #6d4bf2;
  border: 0.5px solid #6d4bf2;
  margin-top: 3rem !important;
}

.registration {
  display: flex;
  align-items: baseline;
  margin-bottom: 1.5rem;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.privacy-policy-text {
  margin-top: 10px;
  margin-left: 12px;
  flex-basis: 100%;
  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}

.privacy-policy-link {
  color: #6d4bf2;
  text-decoration: underline;
}

.reset-password {
  margin-bottom: 2.1rem !important;
}

.heading-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: 1rem;
  width: 100%;
}

#request-access {
  padding-left: 0px;
  padding-top: 0px;
}

.login-view:deep(.amplify-auth-container) {
  padding-bottom: 0px;
}

.login-view:deep(.amplify-authenticator__column) {
  flex-wrap: wrap !important;
}
</style>
