import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

function formatDatetime(date_str, format_str = "DD.MM.YYYY - HH:mm", incoming_format_str = "") {
  if (typeof date_str === "undefined" || date_str === "now") {
    return dayjs().format(format_str);
  }
  if (incoming_format_str.length) {
    dayjs.extend(customParseFormat);
    return dayjs(date_str, incoming_format_str).format(format_str);
  } else {
    return dayjs(date_str).format(format_str);
  }
}

function formatDate(date_str, format_str = "DD.MM.YYYY") {
  if (typeof date_str === "undefined" || date_str === "now") {
    return dayjs().format(format_str);
  } else {
    return dayjs(date_str).format(format_str);
  }
}

function isDateStringValid(date_str) {
  return dayjs(date_str).isValid();
}

export { formatDatetime, formatDate, isDateStringValid };
