//console.log(import.meta.env);
import { createApp } from "vue";

import { Amplify, Logger } from "aws-amplify";
import AmplifyVue from "@aws-amplify/ui-vue";
import awsExports from "@/aws-exports";
import VueGtag from "vue-gtag";

import App from "./App.vue";
import router from "./router";
import { useContextStore } from "@/stores/contextStore";
import vuetify from "@/plugins/vuetify";
import pinia from "@/plugins/pinia";
import sentry from "@/plugins/sentry";
import "../node_modules/nprogress/nprogress.css";

// AWS
Logger.LOG_LEVEL = "WARN";
Amplify.configure(awsExports);

// Vue app
const app = createApp(App);
app.use(sentry);
app.use(pinia);
app.use(router);
app.use(AmplifyVue);
app.use(vuetify);
app.use(
  VueGtag,
  {
    config: { id: import.meta.env.VITE_APP_GA_MEASUREMENT_ID },
  },
  router
);
app.mount("#app");

// theses lines should be after app.use(pinia)
const contextStore = useContextStore();

let context = {
  node_env: __NODE_ENV__,
  app_env: __APP_ENV__,
  app_version: __APP_VERSION__,
  app_build_date: __APP_BUILD_DATE__,
  app_commit_hash: GITVERSION, // see vite.config.js GitRevision
};

contextStore.$patch({ context });
