import { getFileExtensionFromFileName, getFileFormatFromExtension } from "./file";

async function retrieveImageDimensions(file) {
  return new Promise((resolve, reject) => {
    let image = new Image();
    image.onload = () => resolve([image.width, image.height]);
    image.onerror = reject;
    image.src = URL.createObjectURL(file);
  });
}

// augmentDataForImage is a function that will add meta data to the file object
async function getImageMeta(file, file_type) {
  let [width, height] = await retrieveImageDimensions(file);
  let extension = getFileExtensionFromFileName(file.name);
  let format = getFileFormatFromExtension(extension);
  let meta = {
    type: file_type,
    extension: extension,
    width: width,
    height: height,
    stream_size: file.size,
    format: format,
  };

  return meta;
}

export { retrieveImageDimensions, getImageMeta };
