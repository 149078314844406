<script setup>
import EventBus from "@/classes/EventBus";
import { ref, onUnmounted } from "vue";

var snackbar_data = ref({
  snackbar: false,
  color: "ds-primary-grey",
  timeout: 5000,
  text: "",
  location: "bottom",
});

EventBus.$on("SnackBar-show", (payload_snackbar_data) => {
  var color = payload_snackbar_data?.color ?? "info";
  var timeout = snackbar_data.value.timeout ?? 5000;
  var text = payload_snackbar_data?.text ?? "";
  var location = payload_snackbar_data?.location ?? "bottom";

  snackbar_data.value.snackbar = true;
  snackbar_data.value.text = text;
  snackbar_data.value.color = color;
  snackbar_data.value.timeout = timeout;
  snackbar_data.value.location = location;
});

onUnmounted(() => {
  EventBus.$off("SnackBar-show");
});
</script>

<template>
  <v-snackbar
    v-model="snackbar_data.snackbar"
    :color="snackbar_data.color"
    :location="snackbar_data.location"
    :timeout="snackbar_data.timeout"
    multi-line
    class="snackbar-container"
  >
    {{ snackbar_data.text }}

    <template v-slot:actions>
      <div class="close-snackbar-button-container">
        <v-btn variant="flat" :ripple="false" @click="snackbar_data.snackbar = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
    </template>
  </v-snackbar>
</template>

<style scoped>
:deep(.v-snackbar__wrapper) {
  display: flex;
  width: 400px;
  padding: 16px 16px 16px 16px;
  flex-direction: row;
  align-items: center;
  gap: 16px;

  border-radius: 24px;
  background: #fff !important;
  box-shadow: 0px 6px 6px 0px rgba(109, 75, 242, 0.15), 0px 2px 2px 0px rgba(109, 75, 242, 0);
}

:deep(.v-snackbar__content) {
  padding: 0;
  color: #434343 !important;

  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}

:deep(.v-snackbar__actions) {
  align-self: center;
}

.close-snackbar-button-container {
  color: #6d4bf2;
  width: 24px;
  height: 24px;
  align-self: flex-start;
}

.close-snackbar-button-container > .v-btn {
  min-width: 24px;
  min-height: 24px;
  height: 24px;
  padding: 0 !important;
}
</style>
