export const SUBTYPES = {
  mapping_image: "mapping_image",
  recording_video: "recording_video",
};

export default class UploadFile {
  // new UploadFile({ attribute: 42 })
  constructor(props) {
    if (typeof props === "object") {
      Object.assign(this, props);
    }
  }

  //static isInUploadList(uploadfile) {
  //  console.log("isInUploadList");
  //}

  // ylorenz : maybe can do that better ?
  // wrappedObject = mapping OR traffic location OR traffic light location
  static from_file(file, wrappedObject, subtype) {
    let origin_string = wrappedObject.getOriginString();

    if (subtype === SUBTYPES.mapping_image) {
      origin_string += ` (Mapping image)`;
    } else if (subtype === SUBTYPES.recording_video) {
      origin_string += ` (Recording video)`;
    }

    let attributes = {
      file: file,
      upload_progress: null,
      upload_speed: null,
      progresses: [],
      upload_promise: null,
      errors: [],
      directory: wrappedObject.getS3Directory(),
      origin: {
        object_reference: wrappedObject,
        type: wrappedObject.getType(),
        id: wrappedObject.getId(),
        subtype: subtype,
        str: origin_string,
      },
    };

    return new UploadFile(attributes);
  }
}
