import "@mdi/font/css/materialdesignicons.css";
import "vuetify/styles";

import { createVuetify } from "vuetify";
import { aliases, mdi } from "vuetify/iconsets/mdi";
import { VDataTable } from "vuetify/components/VDataTable";

export default createVuetify({
  components: {
    VDataTable,
  },
  theme: {
    defaultTheme: "light",
    themes: {
      light: {
        colors: {
          primary: "#4395F7",
          secondary: "#f7a543",
          background: "#FBF5FF",
          "ds-primary-violet": "#6D4BF2",
          "ds-primary-darkviolet": "#281868",
          "ds-primary-purple": "#D2A7EF",
          "ds-primary-grey": "#FBF5FF",
          "ds-primary-black": "#141414",
          "ds-primary-white": "#FFFFFF",
          "ds-secondary-red": "#FB273E",
          "ds-secondary-lightpurple": "#E7C4F8",
          "ds-secondary-lavender": "#DA78FD",
          "ds-secondary-amethyst": "#AC85F1",
          "ds-secondary-grey": "#E8E8E8",
          "ds-worldview-background": "#E8E8E8",
          "ds-worldview-mesh-loading": "#E8E8E8",
          "ds-worldview-box-material": "#D2A7EF",
          "ds-worldview-box-edges-bottom": "#6D4BF2",
          "ds-worldview-box-edges-front": "#FB273E",
          "ds-worldview-box-edges-other": "#AC85F1",
          "ds-worldview-box-track": "#DA78FD",
        },
      },
    },
  },
  icons: {
    defaultSet: "mdi",
    aliases,
    sets: {
      mdi,
    },
  },
});
