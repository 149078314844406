/* see https://docs.amplify.aws/lib/client-configuration/configuring-amplify-categories/q/platform/js/ */

export default {
  Auth: {
    region: import.meta.env.VITE_APP_REGION,
    identityPoolId: import.meta.env.VITE_APP_COGNITO_IDENTITY_POOL_ID,
    userPoolId: import.meta.env.VITE_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: import.meta.env.VITE_APP_COGNITO_CLIENT_ID,
    mandatorySignIn: true,
  },
  Storage: {
    AWSS3: {
      bucket: import.meta.env.VITE_APP_SUPPLIER_BUCKET,
      region: import.meta.env.VITE_APP_SUPPLIER_BUCKET_REGION,
    },
  },
};
