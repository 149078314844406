import { getImageMeta } from "./image_utils";
import { getVideoMeta, thumbnail_extension } from "./video_utils";
import * as Sentry from "@sentry/vue";

function getFileNameFromPath(path) {
  return path.substr(path.lastIndexOf("/") + 1);
}

function getLastFolderNameFromPath(path) {
  return path.match(/([^\/]*)\/*$/)[1];
}

// getFileExtentionFromFileName is a function that will return the extension of the file
function getFileExtensionFromFileName(fileName) {
  return fileName.includes(".") ? fileName.split(".").pop().toLowerCase() : null;
}

// getFileTypeFromFileName is a function that will return the type of the file based on the extension
function getFileTypeFromFileName(filename) {
  const extension = getFileExtensionFromFileName(filename);

  let file_type = null;
  switch (extension.toUpperCase()) {
    case "MP4":
      file_type = "video";
      break;
    case "JPEG":
    case "JPG":
    case "PNG":
      file_type = "image";
      break;
    case "SRT":
      file_type = "srt";
      break;
    default:
      throw new Error("Unsupported file type");
  }

  return file_type;
}

// getImageFormatFromExtension is a function that will return the format of the image based on the extension
function getFileFormatFromExtension(extension) {
  switch (extension.toUpperCase()) {
    case "JPEG":
      return "JPEG";
    case "JPG":
      return "JPEG";
    case "PNG":
      return "PNG";
    default:
      return extension.toUpperCase();
  }
}

function getSrtMeta(file) {
  let meta = {
    type: "srt",
    extension: "srt",
    stream_size: file.size,
  };

  return meta;
}

function getGeneralMeta(file) {
  let extension = getFileExtensionFromFileName(file.name);
  let format = getFileFormatFromExtension(extension);
  let meta = {
    type: "general",
    extension: getFileExtensionFromFileName(file.name),
    stream_size: file.size,
    format: format,
  };
  if (file?.extra?.xyz) {
    meta.xyz = file?.extra?.xyz; // xyz
  }

  return meta;
}

async function augmentWithMeta(file) {
  let file_type = getFileTypeFromFileName(file.name);

  try {
    let meta_fn = null;
    if (file_type === "image") {
      meta_fn = getImageMeta;
    } else if (file_type === "video") {
      meta_fn = getVideoMeta;
    } else if (file_type === "srt") {
      meta_fn = getSrtMeta;
    } else {
      meta_fn = getGeneralMeta;
    }

    file.meta = await meta_fn(file);
  } catch (error) {
    console.error(`Error while computing meta for ${file.name}: ${error}`);
    Sentry.captureException(error);
    file.meta = { error: error };
  }
  console.log(`Computed meta for ${file.name}: ${Object.entries(file.meta)}`);
}

function isGpmf(file) {
  return file.key.toLowerCase().endsWith(".gpmf");
}

// Only call this function on files that are inside a directory of content_type "video"!
function isThumbnail(file, directory_content_type) {
  if (directory_content_type === "video") {
    return file.key.toLowerCase().endsWith(thumbnail_extension);
  } else {
    return false;
  }
}

function not(f) {
  return function (...args) {
    return !f.apply(null, args);
  };
}

export {
  getFileNameFromPath,
  getLastFolderNameFromPath,
  getFileTypeFromFileName,
  augmentWithMeta,
  getFileExtensionFromFileName,
  getFileFormatFromExtension,
  isGpmf,
  isThumbnail,
  not,
};
