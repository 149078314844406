<script setup>
import { formatSizeFromBytes } from "@/utils/text";

function formatFileList(fileList, size) {
  if (fileList == null) fileList = [];
  const nFiles = fileList.length;
  let text = `${nFiles} file`;
  if (nFiles > 1) {
    text += "s";
  }
  if (size) {
    const sizeInBytes = fileList.reduce((totalSize, { size }) => totalSize + size, 0);
    const sizeFormated = formatSizeFromBytes(sizeInBytes);
    text += `, ${sizeFormated}`;
  }
  return text;
}

const props = defineProps({
  files: {
    type: Array,
    required: false,
    default: null,
  },
  icon: {
    type: Boolean,
    default: function () {
      return false;
    },
  },
  size: {
    type: Boolean,
    default: false,
  },
});
</script>

<template>
  <template v-if="props.icon">
    <v-icon v-if="props.files && props.files.length" icon="mdi-file-check" color="green"> </v-icon>
    <!-- ylorenz idea : sync icon when request file_list
      <v-icon v-else icon="mdi-file-sync" color="ds-secondary-red"> </v-icon>
    -->
    <v-icon v-else icon="mdi-file-remove" color="ds-secondary-red"> </v-icon>
  </template>
  {{ formatFileList(props.files, props.size) }}
</template>
