<script setup>
import { ref, onMounted, onUnmounted, computed, watch } from "vue";
import { event as gtag } from "vue-gtag";

import EventBus from "@/classes/EventBus";
import { s3Remove, s3List, s3Get } from "@/utils/awsStorage";
import { getFileNameFromPath, getFileTypeFromFileName } from "@/utils/file";
import { formatSizeFromBytes } from "@/utils/text";

import FileInfos from "@/components/FileInfos";
import DeleteButton from "@/components/supply/DeleteButton";
import PreviewButton from "@/components/supply/PreviewButton";
import { isGpmf, isThumbnail, not } from "../utils/file";
import { thumbnail_extension } from "../utils/video_utils";

const directories = ref([]);
const title = ref(null);

const dialog = ref(false);

const file_list_by_directory = ref([]);
const file_list_loading = ref(false);

const dialog_delete = ref(false);
const dialog_delete_file = ref(null);

const dialog_preview_image = ref(null);
const dialog_preview_image_file = ref(null);

const dialog_preview_video = ref(null);
const dialog_preview_video_file = ref(null);

onMounted(() => {
  EventBus.$on("AwsFileBrowser-show", (params) => {
    console.log("EventBus AwsFileBrowser-show", params);
    dialog.value = true;
    directories.value = params?.directories ?? [];
    title.value = params?.title ?? null;
  });

  EventBus.$on("AwsFileBrowser-hide", (params) => {
    //console.log("EventBus AwsFileBrowser-hide", params);
    close();
  });
});

onUnmounted(() => {
  EventBus.$off("AwsFileBrowser-show");
  EventBus.$off("AwsFileBrowser-hide");
});

const file_list_flat = computed(() => {
  return file_list_by_directory.value.reduce((accumulator, v) => {
    return accumulator.concat(v.files);
  }, []);
});

watch(dialog, async (newValue, oldValue) => {
  //console.log("watch dialog", newValue, oldValue);

  if (newValue) {
    updateFileList();
  } else {
    EventBus.$emit("AwsFileBrowser-hidden");
  }
});

const openConfirmDeleteDialog = function (file) {
  //console.log("openConfirmDeleteDialog", file);
  dialog_delete.value = true;
  dialog_delete_file.value = file;
};

const openPreviewImageDialog = function (file) {
  dialog_preview_image.value = true;

  s3Get(file.key, {})
    .then((response) => {
      dialog_preview_image_file.value = response;
    })
    .catch((error) => {
      console.error("openImageInNewTab error", error);
    });
};

const openPreviewVideoDialog = function (file) {
  dialog_preview_image.value = true;
  const thumbnail_key = file.key.substring(0, file.key.lastIndexOf(".")) + thumbnail_extension;

  s3Get(thumbnail_key, {})
    .then((response) => {
      dialog_preview_image_file.value = response;
    })
    .catch((error) => {});
};

const closeConfirmDeleteDialog = function () {
  dialog_delete.value = false;
  dialog_delete_file.value = null;
};

const closePreviewImageDialog = function () {
  dialog_preview_image.value = false;
  dialog_preview_image_file.value = null;
};

const closePreviewVideoDialog = function () {
  dialog_preview_video.value = false;
  dialog_preview_video_file.value = null;
};

async function deleteFile(file) {
  file.deleting = true;

  gtag("Deletion_file", { event_category: "Supply", value: 13 });

  const data = await s3Remove(file.key);
  console.log("s3Remove then", data, file.key);
  file.deleting = false;

  file_list_by_directory.value.map((directory) => {
    let index = directory.files.indexOf(file);
    if (index !== -1) {
      directory.files.splice(index, 1);
    }
  });

  // check if file is video and delete the corresponding thumbnail and gopro file (if any)
  if (file.file_type === "video") {
    // check if the video file is a gopro file, so we also remove the gmpf file
    if (getFileNameFromPath(file.key).startsWith("GX")) {
      await s3Remove(file.key.slice(0, -3) + "gpmf");
    }

    const thumbnail_key = file.key.replace(/\.mp4/i, thumbnail_extension);
    const data = await s3Remove(thumbnail_key);
    console.log("s3Remove then", data, thumbnail_key);
  }

  closeConfirmDeleteDialog();
}

const close = function () {
  file_list_loading.value = false;
  file_list_by_directory.value = [];

  dialog.value = false;
};

const updateFileList = function () {
  //console.log("updateFileList", directories.value);
  file_list_loading.value = true;

  let promises = [];
  directories.value.map((directory) => {
    //console.log("directories.map directory", directory);

    const directory_promise = new Promise((resolve, reject) => {
      s3List(directory.path)
        .then((response) => {
          //console.log("s3List then", response.results);
          // simulate AWS slowness
          //setTimeout(() => {
          resolve({ ...directory, files: response.results });
          //}, 2000);
        })
        .catch((err) => {
          console.log("s3List catch", err);
          reject(err);
        });
    });

    promises.push(directory_promise);
  });

  return Promise.all(promises)
    .then((directory_responses) => {
      //console.log("Promise.all", directory_responses);

      file_list_loading.value = false;
      file_list_by_directory.value = [];

      directory_responses.map((directory) => {
        let directory_files;
        if (directory.content_type === "video") {
          directory_files = directory.files
            .filter(not(isGpmf))
            .filter(not((f) => isThumbnail(f, directory.content_type)));
        } else {
          directory_files = directory.files.filter(not(isGpmf));
        }
        const video_directory_files_mapped = directory_files.map((f) => {
          f.file_name = getFileNameFromPath(f.key);
          f.file_type = getFileTypeFromFileName(f.file_name);
          f.deleting = false;
          return f;
        });
        file_list_by_directory.value.push({
          title: directory.title,
          content_type: directory.content_type,
          files: video_directory_files_mapped,
        });
      });
    })
    .catch((errors) => {
      console.log("catch errors from promises", errors);
    });
};
</script>

<template>
  <v-dialog v-model="dialog_preview_image" width="auto">
    <v-card class="preview-image-dialog" v-if="dialog_preview_image_file">
      <img width="800px" :src="dialog_preview_image_file" id="img_preview" />
      <div class="dialog-button-container">
        <v-btn
          @click="closePreviewImageDialog"
          class="small-button small-button--no-icon close-dialog-button"
          :ripple="false"
          variant="flat"
          id="btnCloseImagePreview"
          >Close</v-btn
        >
      </div>
    </v-card>
  </v-dialog>

  <v-dialog v-model="dialog_preview_video" width="auto">
    <v-card class="preview-image-dialog" v-if="dialog_preview_video_file">
      <video width="800px" controls>
        <source :src="dialog_preview_video_file" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div class="dialog-button-container">
        <v-btn
          @click="closePreviewVideoDialog"
          class="small-button small-button--no-icon close-dialog-button"
          :ripple="false"
          variant="flat"
          id="btnCloseVideoPreview"
          >Close</v-btn
        >
      </div>
    </v-card>
  </v-dialog>

  <v-dialog v-model="dialog_delete" width="auto">
    <v-card class="delete-file-dialog" v-if="dialog_delete_file">
      <h1>Delete File "{{ dialog_delete_file.file_name }}"</h1>

      <div class="dialog-button-container">
        <v-btn
          @click="closeConfirmDeleteDialog"
          class="small-button small-button--no-icon close-dialog-button"
          :ripple="false"
          variant="flat"
          >Cancel</v-btn
        >
        <v-btn
          color="ds-primary-violet"
          @click="deleteFile(dialog_delete_file)"
          :loading="dialog_delete_file.deleting"
          :disabled="dialog_delete_file.deleting"
          class="small-button small-button--no-icon delete-file-button"
          :ripple="false"
          id="btnDeleteAwsFileBrowserConfirm"
          variant="flat"
        >
          Delete file
        </v-btn>
      </div>
    </v-card>
  </v-dialog>

  <v-dialog v-model="dialog" width="auto" min-width="400px" scrollable>
    <v-card class="uploaded-files-info-dialog">
      <div class="uploaded-files-info-dialog-title">
        <h2>{{ title }}</h2>

        <FileInfos :files="file_list_flat" size v-if="!file_list_loading" />
      </div>

      <div v-if="file_list_loading" class="loading text-center">
        <v-progress-circular indeterminate color="ds-primary-violet"></v-progress-circular>
      </div>
      <div class="uploaded-files-info-dialog-file-sections" v-if="!file_list_loading">
        <div
          v-for="directory in file_list_by_directory"
          :key="directory.title"
          class="uploaded-files-info-dialog-section"
        >
          <span class="uploaded-files-info-dialog-section-title">{{ directory.title }}</span>
          <template v-if="!directory.files.length">
            <div class="text-center">No files</div>
          </template>
          <template v-else>
            <div class="uploaded-files-info-dialog-file-keys">
              <span class="uploaded-files-info-dialog-file-title">File name</span>
              <span class="uploaded-files-info-dialog-file-size">Size</span>
              <span class="empty-span"></span>
              <span class="empty-span"></span>
            </div>
            <div v-for="file in directory.files" :key="file.key" class="uploaded-files-info-dialog-file">
              <span class="uploaded-files-info-dialog-file-title">{{ file.file_name }}</span>
              <span class="uploaded-files-info-dialog-file-size">{{ formatSizeFromBytes(file.size) }}</span>

              <delete-button id="btnDeleteAwsFileBrowser" @on_click="openConfirmDeleteDialog(file)" />
              <preview-button
                id="btnPreviewAwsFileBrowser"
                :disabled="!(file.file_type === 'image' || file.file_type === 'video')"
                @on_click="
                  directory.content_type === 'image' ? openPreviewImageDialog(file) : openPreviewVideoDialog(file)
                "
              />
            </div>
          </template>
        </div>
      </div>
      <div class="dialog-button-container dialog-button-container__no-margin">
        <v-btn
          color="ds-primary-violet"
          @click="close"
          class="small-button small-button--no-icon delete-location-button"
          :ripple="false"
          variant="flat"
          id="btnCloseAwsFileBrowser"
        >
          Close
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<style scoped>
.text-center {
  color: #141414;
  text-align: center;
  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  padding: 16px 0px;
}

.loading.text-center {
  width: 100%;
  height: auto;
}

.delete-file-dialog {
  display: flex;
  width: 624px;
  padding: 48px !important;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 24px !important;
  background: #fff;
  box-shadow: none !important;
}

.preview-image-dialog {
  display: flex;
  width: 100%;
  padding: 48px !important;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 24px !important;
  background: rgb(var(--v-theme-ds-primary-white));
  box-shadow: none !important;
}

.delete-file-dialog h1 {
  color: #141414;
  align-self: stretch;
  font-family: Manrope;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px;
}

.dialog-button-container {
  display: flex;
  flex-direction: row;
  height: 52px;
  justify-content: flex-end;
  margin-top: 48px;
  width: 100%;
}

.dialog-button-container__no-margin {
  margin-top: 0;
}

.close-dialog-button {
  border: 1px solid #6d4bf2;
  background: #fff;

  color: #6d4bf2 !important;
}

.close-dialog-button:hover {
  background: #6d4bf2;

  color: #fff !important  ;
}

.delete-file-button {
  background: #6d4bf2 !important;
  color: #fff;
  margin-left: 24px;
}

.uploaded-files-info-dialog {
  display: flex;
  width: 624px;
  padding: 48px !important;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 24px !important;
  background: #fff;
  gap: 32px;

  box-shadow: none !important;
}

.uploaded-files-info-dialog-file-sections {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 32px;
  overflow-y: scroll;
}

.uploaded-files-info-dialog-file-sections::-webkit-scrollbar {
  width: 6px;
}

.uploaded-files-info-dialog-file-sections::-webkit-scrollbar-thumb {
  background: #b9b9b9;
  border-radius: 18px;
}

.uploaded-files-info-dialog:deep(.v-card__underlay) {
  display: none !important;
}

.uploaded-files-info-dialog-title {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.uploaded-files-info-dialog-title h2 {
  color: #141414;
  font-family: Manrope;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px;
}

.uploaded-files-info-dialog-section-title {
  color: #5b5b5b;

  font-family: Manrope;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 2.8px;
  text-transform: uppercase;
  display: block;
  margin-bottom: 16px;
}

.uploaded-files-info-dialog-file,
.uploaded-files-info-dialog-file-keys {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 0px;
  border-bottom: 1px solid #e8e8e8;
  gap: 16px;
  width: 100%;
}

.uploaded-files-info-dialog-file-keys > .uploaded-files-info-dialog-file-title,
.uploaded-files-info-dialog-file-keys > .uploaded-files-info-dialog-file-size {
  color: #5b5b5b;
  font-family: Manrope;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.uploaded-files-info-dialog-file-title,
.uploaded-files-info-dialog-file-size {
  color: #141414;
  font-family: Manrope;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.uploaded-files-info-dialog-section {
  width: 100%;
}

.uploaded-files-info-dialog-file-title {
  width: 80%;
}

.uploaded-files-info-dialog-file-size {
  width: 80px;
}

.empty-span {
  width: 40px;
}
</style>
