class PrivacyPolicyRelease {
  version: string;
  date: Date;

  constructor(version: string, date: Date = new Date("2024-06-13")) {
    this.version = version;
    this.date = date;
  }

  hasUserAcceptedLatestPrivacyPolicy(privacy_policy_date_user: Date | undefined | null): boolean {
    // If the user has not accepted any privacy policy, return false
    if (privacy_policy_date_user === undefined || privacy_policy_date_user === null) {
      return false;
    }

    // Get the latest privacy policy release date
    const privacy_policy_curr = this.date.getTime();
    let user_privacy_policy_time = privacy_policy_date_user.getTime();

    return user_privacy_policy_time >= privacy_policy_curr;
  }
}

export { PrivacyPolicyRelease };
