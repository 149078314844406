import { getFileExtensionFromFileName, getFileTypeFromFileName } from "./file";

import MediaInfoFactory from "mediainfo.js";

const thumbnail_extension = ".jpg";
let mediainfo_promise = new Promise((resolve, reject) => {
  MediaInfoFactory(
    {
      format: "object",
      coverData: false, // "JSON",
      full: false,
      locateFile: (path, prefix) => {
        return window.location.origin + "/" + path; // MediaInfoModule.wasm file is at the root of the URL ; see vite.config.js
      },
    },
    (instance) => {
      resolve(instance);
    },
    (error) => {
      reject(error);
    }
  );
});
async function analyzeVideo(file) {
  let mediainfo = await mediainfo_promise;
  const getSize = () => file.size;
  const readChunk = (chunkSize, offset) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        if (event.target.error) {
          reject(event.target.error);
        }
        resolve(new Uint8Array(event.target.result));
      };
      reader.readAsArrayBuffer(file.slice(offset, offset + chunkSize));
    });
  };
  return mediainfo.analyzeData(getSize, readChunk);
}

async function getVideoMeta(file) {
  const result = await analyzeVideo(file);
  const tracks = result.media?.track ?? [];
  const video_track = tracks.find((track) => track["@type"] == "Video");
  let meta = {
    type: getFileTypeFromFileName(file.name), // can be overidden later, but we are sure to always have type if no tracks !
    extension: getFileExtensionFromFileName(file.name),
  };

  if (video_track) {
    meta.type = "video";
    meta.format = video_track.Format; // "AVC"
    meta.stream_size = Number(video_track.StreamSize); // MediaDataSize
    meta.width = Number(video_track.Width); // VideoWidth
    meta.height = Number(video_track.Height); // VideoHeight
    meta.encoded_date = video_track.Encoded_Date; // CreateDate
    meta.duration = Number(video_track.Duration); // Duration
    meta.frame_rate = Number(video_track.FrameRate); // VideoFrameRate
  }

  return meta;
}

export { getVideoMeta, thumbnail_extension };
