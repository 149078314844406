import { createRouter, createWebHistory } from "vue-router";

import NotFoundView from "../views/NotFoundView.vue";
import LoginView from "../views/LoginView.vue";

import { can } from "@/utils/aclRules";

import NProgress from "nprogress";

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: "/error",
      name: "notfound",
      component: NotFoundView,
    },
    {
      path: "/",
      name: "login",
      component: LoginView,
      meta: {
        can: "role-guest",
      },
    },
    {
      path: "/admin",
      component: () => import("../views/admin/AdminView.vue"),
      redirect: { name: "admin-user-list" },
      children: [
        {
          path: "users",
          name: "admin-user-list",
          component: () => import("../views/admin/UserListView.vue"),
          meta: {
            can: "role-admin",
          },
        },
        {
          path: "datasets",
          name: "admin-dataset-list",
          component: () => import("../views/admin/DatasetListView.vue"),
          meta: {
            can: "role-admin",
          },
        },
      ],
    },
    {
      path: "/supply",
      component: () => import("../views/supply/SupplyView.vue"),
      redirect: { name: "supply-project-list" },
      children: [
        {
          path: "project-list",
          name: "supply-project-list",
          component: () => import("../views/supply/ProjectListView.vue"),
          meta: {
            can: "role-user",
          },
        },
        {
          path: "project/:name/:mapping_index?",
          name: "supply-project-detail",
          component: () => import("../views/supply/ProjectDetailView.vue"),
          props: (route) => {
            //console.log(`Routing to project ${route.params.name} mapping ${route.params.mapping_index}`);
            // See https://forum.vuejs.org/t/vue-router-parameter-as-an-array/7754/3
            const mapping_index = route.params.mapping_index || "1";
            return {
              ...route.params,
              mapping_index: Number(mapping_index),
            };
          },
          meta: {
            can: "role-user",
          },
        },
        {
          path: "instructions",
          name: "supply-instructions",
          component: () => import("../views/supply/InstructionsView.vue"),
          meta: {
            can: "role-user",
          },
        },
        {
          path: "support",
          name: "supply-support",
          component: () => import("../views/SupportFormView.vue"),
          meta: {
            can: "role-user",
          },
        },
      ],
    },
    {
      path: "/explore",
      component: () => import("../views/explore/ExploreView.vue"),
      redirect: { name: "explore-release-list" },
      children: [
        {
          path: "release-list",
          name: "explore-release-list",
          component: () => import("../views/explore/ReleaseListView.vue"),
          meta: {
            can: "role-user",
          },
        },
        {
          path: "support",
          name: "explore-support",
          component: () => import("../views/SupportFormView.vue"),
          meta: {
            can: "role-user",
          },
        },
        {
          path: "conventions",
          name: "explore-conventions",
          component: () => import("../views/explore/ConventionsView.vue"),
          meta: {
            can: "role-user",
          },
        },
        {
          path: "non-commercial-license",
          name: "explore-non-commercial-license",
          component: () => import("../views/explore/NonCommercialLicenseView.vue"),
          meta: {
            can: "role-user",
          },
        },
        {
          path: "release/:release_name",
          component: () => import("../views/explore/ReleaseView.vue"),
          redirect: { name: "explore-release-locations" },
          children: [
            {
              // this route is used to retrieve the release information (e.g. recording_name and location_id) before
              // redirecting to the explore-release-location-recording route (defined below)

              // This is not called when the user opens the URL directly from the browser, instead the route below!
              name: "explore-release-locations",
              path: "locations",
              component: () => import("../views/explore/ReleaseLoaderView.vue"),
              meta: {
                can: "role-user",
              },
            },
            {
              path: "location/:location_id/:recording_name",
              name: "explore-release-location-recording",
              component: () => import("../views/explore/ReleaseRecordingView.vue"),
              meta: {
                can: "role-user",
              },
            },
          ],
        },
        {
          name: "explore-release-alias",
          path: "release/alias/:release_alias",
          meta: {
            can: "role-user",
          },
          redirect: { name: "explore-release-locations-alias" },
          component: () => import("../views/explore/ReleaseView.vue"),
          children: [
            {
              // this route is used to retrieve the release information (e.g. recording_name and location_id) before
              // redirecting to the explore-release-location-recording-alias route (defined below)
              name: "explore-release-locations-alias",
              path: "locations",
              component: () => import("../views/explore/ReleaseLoaderView.vue"),
              meta: {
                can: "role-user",
              },
            },
            {
              path: "location/:location_id/:recording_index",
              name: "explore-release-location-recording-alias",
              component: () => import("../views/explore/ReleaseRecordingView.vue"),
              meta: {
                can: "role-user",
              },
            },
          ],
        },
      ],
    },
    {
      path: "/:pathMatch(.*)*",
      name: "not-found",
    },
  ],
});

NProgress.configure({
  showSpinner: false,
  trickleSpeed: 200,
  minimum: 0.3,
  easing: "ease",
  speed: 500,
  template: '<div class="bar" role="bar"></div>',
});

router.beforeResolve((to, from, next) => {
  if (to.name) {
    NProgress.start();
  }
  next();
});
router.beforeEach((to, from, next) => {
  //console.group("beforeEach", from, to);
  //console.group("beforeEach to params", to.params);
  let to_meta_can = to?.meta?.can ?? "role-guest";
  if (can(to_meta_can)) {
    next();
  } else {
    if (can("role-user")) {
      // redirect into root of the current space
      if (to.path.startsWith("/supply")) {
        next({ name: "supply-project-list" });
      } else if (to.path.startsWith("/explore")) {
        next({ name: "explore-release-list" });
      } else {
        // default
        next({ name: "supply-project-list" });
      }
    } else {
      let redirect_url = window.location.href;
      let origin = window.location.origin;

      if (redirect_url !== origin) {
        next({ name: "login", query: { redirect_url: redirect_url } });
      } else {
        next({ name: "login" });
      }
    }
  }

  return true;
});

router.afterEach(() => {
  NProgress.done();
});

export default router;
