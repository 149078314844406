class EventBus {
  constructor() {
    this.events = {};
  }

  $on(eventName, fn) {
    //console.log("EventBus $on", eventName);
    this.events[eventName] = this.events[eventName] || [];
    this.events[eventName].push(fn);
  }

  $off(eventName, fn) {
    //console.log("EventBus $off", eventName, fn);
    if (this.events[eventName]) {
      // If no function is passed then remove all eventName
      if (typeof fn === "undefined") {
        delete this.events[eventName];
      } else {
        for (var i = 0; i < this.events[eventName].length; i++) {
          //console.log("EventBus $off for ", i);
          if (this.events[eventName][i] === fn) {
            //console.log("EventBus $off for splice ", i);
            this.events[eventName].splice(i, 1);
            break;
          }
        }
      }
    }
  }

  $emit(eventName, data) {
    if (this.events[eventName]) {
      this.events[eventName].forEach(function (fn) {
        fn(data);
      });
    }
  }
}

export default new EventBus();
