<script setup>
import { ref, onMounted, onUnmounted, watch, computed } from "vue";
import EventBus from "@/classes/EventBus";
import { uploadAsset, s3Cancel } from "@/utils/awsStorage";
import * as Sentry from "@sentry/vue";

import { formatSizeFromBytes, formatSizeFromBytesWithoutUnits, formatTimeFromSeconds } from "@/utils/text";

import DeleteButton from "@/components/supply/DeleteButton";

import { useUploadStore } from "@/stores/uploadStore";
import { augmentWithMeta, getFileTypeFromFileName } from "@/utils/file";
import { shapeToString } from "@/utils/format";
import { SUBTYPES } from "@/classes/UploadFile";

const uploadStore = useUploadStore();

const dialog = ref(false);

const dialog_abort = ref(false);
const dialog_abort_file = ref(null);

const table_headers = [
  {
    title: "Status",
    sortable: false,
    key: "status",
  },
  {
    title: "Speed",
    sortable: false,
    key: "speed",
  },
  {
    title: "Type",
    sortable: false,
    key: "type",
  },
  {
    title: "Origin",

    sortable: false,
    key: "origin",
  },
  {
    title: "Filename",
    key: "file.name",
    sortable: false,
  },
  {
    title: "Size",
    key: "file.size",
    sortable: false,
  },
  {
    title: "",
    key: "action",
    sortable: false,
  },
];

const sortBy = []; // { key: "size", order: "desc" }

onMounted(() => {
  EventBus.$on("UploadFileManager-show", (params) => {
    // console.log("EventBus UploadFileManager-show", params);
    dialog.value = true;
  });

  EventBus.$on("UploadFileManager-hide", (params) => {
    //console.log("EventBus UploadFileManager-hide", params);
    close();
  });

  EventBus.$on("UploadFileManager-upload-start", () => {
    //console.log("UploadFileManager-upload-start", uploadStore.uploadStatus);
    uploadStep();
    dialog.value = true;
  });
});

onUnmounted(() => {
  EventBus.$off("UploadFileManager-show");
  EventBus.$off("UploadFileManager-hide");
  EventBus.$off("UploadFileManager-upload-start");
});

async function cancelUploadFile(file) {
  let index = uploadStore.uploadList.indexOf(file);
  if (index !== -1) {
    let upload_file = uploadStore.uploadList[index];

    if (upload_file.upload_promise && upload_file.upload_promise !== "done") {
      await s3Cancel(upload_file.upload_promise, `${upload_file.directory}${upload_file.file.name}`);
    }
    uploadStore.uploadList.splice(index, 1);
    setTimeout(uploadStep, 500);
  }
  EventBus.$emit(getEventString(file, "updateFileList"));
  closeConfirmAbortDialog();
}

const uploadSpeed = computed(() => {
  return uploadStore.uploadList.reduce((accumulator, upload_file) => {
    return accumulator + (upload_file.upload_progress !== 100 ? upload_file.upload_speed : 0);
  }, 0);
});

const uploadedSize = computed(() => {
  return uploadStore.uploadList.reduce((accumulator, upload_file) => {
    return (
      accumulator + (upload_file.upload_progress ? (upload_file.file.size * upload_file.upload_progress) / 100 : 0)
    );
  }, 0);
});

const totalUploadSize = computed(() => {
  return uploadStore.uploadList.reduce((accumulator, upload_file) => {
    return accumulator + upload_file.file.size;
  }, 0);
});

const timeLeft = computed(() => {
  if (uploadSpeed.value == 0) {
    return {
      total: "-",
      items: uploadStore.uploadList.map(() => "-"),
    };
  }

  return {
    total: formatTimeFromSeconds((totalUploadSize.value - uploadedSize.value) / uploadSpeed.value),
    items: uploadStore.uploadList.map((upload_file) => {
      if (upload_file.upload_progress == 100 || !upload_file.upload_progress) return "-";

      return formatTimeFromSeconds(
        (upload_file.file.size - (upload_file.file.size * upload_file.upload_progress) / 100) / uploadSpeed.value
      );
    }),
  };
});

function cleanup() {
  const files_left_to_upload = uploadStore.uploadList.filter((upload_file) => upload_file.upload_promise !== "done");
  if (files_left_to_upload.length === 0) {
    uploadStore.uploadList.splice(0);
    uploadStore.uploadProgress = 0;
    uploadStore.uploadStatus = "done";
  }
}

function close() {
  dialog.value = false;
  cleanup();
}

const openConfirmAbortDialog = function (file) {
  // console.log("openConfirmDeleteDialog", file);
  if (file.upload_promise === "done") {
    let index = uploadStore.uploadList.indexOf(file);
    uploadStore.uploadList.splice(index, 1);
    return;
  }
  dialog_abort.value = true;
  dialog_abort_file.value = file;
};

const closeConfirmAbortDialog = function () {
  //console.log("closeConfirmabortDialog");
  dialog_abort.value = false;
  dialog_abort_file.value = null;
};

watch(uploadStore.uploadList, async (newUploadList, oldUploadList) => {
  let total_bytes = 0;
  let total_uploaded_bytes = 0;
  uploadStore.uploadList.forEach((upload_file) => {
    total_bytes += upload_file.file.size;
    let upload_progress = upload_file.upload_progress ? upload_file.upload_progress : 0;
    total_uploaded_bytes += (upload_file.file.size * upload_progress) / 100;
  });

  let percent = Math.ceil((total_uploaded_bytes / total_bytes || 0) * 100);

  uploadStore.uploadProgress = percent;
});

function isAlreadyUploaded(upload_file) {
  let files_list = upload_file.origin.object_reference.file_list;
  if (upload_file.origin.subtype === "recording_video") {
    files_list = upload_file.origin.object_reference.video_files;
  }

  return files_list.find((file_in_object_list) => {
    return (
      file_in_object_list.size === upload_file.file.size &&
      file_in_object_list.key == `${upload_file.directory}${upload_file.file.name}`
    );
  });
}

async function uploadStep() {
  let upload_file = uploadStore.uploadList.find((upload_file) => upload_file.upload_promise !== "done");
  if (!upload_file) {
    console.log("No more files to upload");
    uploadStore.uploadStatus = "stopped";
  } else if (upload_file.upload_promise !== null) {
    console.log(`File ${upload_file.file.name} already started uploading`);
  } else if (isAlreadyUploaded(upload_file)) {
    console.log(`File ${upload_file.file.name} is already uploaded`);
    upload_file.upload_promise = "done";
    upload_file.upload_progress = 100;
    setTimeout(uploadStep, 500);
  } else {
    console.log(`Trying to upload file ${upload_file.file.name}`);
    await tryUploadFile(upload_file);

    setTimeout(uploadStep, 500);
  }
}

async function uploadFile(upload_file) {
  uploadStore.uploadStatus = "uploading";
  try {
    await uploadAsset(upload_file);
  } catch (err) {
    upload_file.errors.push(err);
    gtag("Error_upload", { event_category: "Supply", value: 17 });
    console.log(`Uploading file ${upload_file} failed with error ${err}`);
    Sentry.captureException(err);
  }
  EventBus.$emit(getEventString(upload_file, "updateFileList"));
}

async function tryUploadFile(upload_file) {
  await augmentWithMeta(upload_file.file);
  const errors = checkForUpdatesAndErrors(upload_file);
  if (errors.length > 0) {
    const text = errors.join("\n");
    EventBus.$emit("SnackBar-show", { text, color: "error" });

    upload_file.upload_promise = "done";
    upload_file.upload_progress = 100;
    console.log(text);
    upload_file.errors.push(text);
  } else {
    await uploadFile(upload_file);
  }
}

function checkForUpdatesAndErrors(upload_file) {
  const object_reference = upload_file.origin.object_reference;
  const file_meta = upload_file.file.meta;
  const errors = [];
  let project_updated = false;

  if (upload_file.origin.subtype === SUBTYPES.mapping_image) {
    const reference_shape = object_reference.getReferenceShapeMapping();
    const image_shape = [file_meta.width, file_meta.height];
    if (reference_shape === null) {
      object_reference.setReferenceShapeMapping(image_shape);
      project_updated = true;
    } else if (shapeToString(image_shape) !== shapeToString(reference_shape)) {
      console.log(`image shape ${image_shape} != reference shape ${reference_shape}`);
      errors.push(
        `File ${upload_file.file.name} does not have the reference resolution of ${shapeToString(reference_shape)}`
      );
    }
  } else if (
    upload_file.origin.subtype === SUBTYPES.recording_video &&
    getFileTypeFromFileName(upload_file.file.name) !== "srt"
  ) {
    const reference_shape = object_reference.getReferenceShapeVideo();
    const video_shape = [file_meta.width, file_meta.height];
    if (reference_shape === null) {
      object_reference.setReferenceShapeVideo(video_shape);
      project_updated = true;
    } else if (shapeToString(video_shape) !== shapeToString(reference_shape)) {
      console.log(`video shape ${video_shape} != refenrece shape ${reference_shape}`);
      errors.push(
        `File ${upload_file.file.name} does not have the reference resolution of ` + `${shapeToString(reference_shape)}`
      );
    }

    const reference_frame_rate = object_reference.getReferenceFrameRate();
    const frame_rate = file_meta.frame_rate;
    if (reference_frame_rate === null) {
      object_reference.setReferenceFrameRate(frame_rate);
      project_updated = true;
    } else if (frame_rate !== reference_frame_rate) {
      console.log(`frame rate ${frame_rate} != refenrece shape ${reference_frame_rate}`);
      errors.push(`File ${upload_file.file.name} does not have the reference frame rate ${reference_frame_rate} Hz`);
    }
  }

  if (project_updated) {
    EventBus.$emit("project-upload");
  }

  return errors;
}

function getEventString(file, event) {
  //console.log("getEventString", file, event);
  let eventString = "";

  if (file.origin.type === "mapping") {
    eventString = `MappingSection-${event}`;
  } else if (file.origin.type === "traffic_location") {
    eventString = `TrafficLocationCard-${file.origin.id}-${event}`;
  } else if (file.origin.type === "traffic_light_location") {
    eventString = `TrafficLightLocationCard-${file.origin.id}-${event}`;
  }

  //console.log("eventString", eventString);
  return eventString;
}
</script>

<template>
  <v-dialog v-model="dialog_abort" width="auto" transition="dialog-bottom-transition">
    <v-card class="abort-file-dialog" v-if="dialog_abort_file">
      <h1>Abort uploading file "{{ dialog_abort_file.file.name }}"</h1>

      <div class="dialog-button-container">
        <v-btn
          @click="closeConfirmAbortDialog"
          class="small-button small-button--no-icon close-dialog-button"
          :ripple="false"
          variant="flat"
          >Cancel</v-btn
        >

        <v-btn
          color="ds-primary-violet"
          @click="cancelUploadFile(dialog_abort_file)"
          :loading="dialog_abort_file.deleting"
          :disabled="dialog_abort_file.deleting"
          class="small-button small-button--no-icon abort-file-button"
          :ripple="false"
          variant="flat"
          id="btnAbortUploadUploadConfirm"
        >
          Abort upload
        </v-btn>
      </div>
    </v-card>
  </v-dialog>

  <v-dialog v-model="dialog" width="auto" min-width="400px" scrollable transition="dialog-bottom-transition">
    <v-card class="upload-dialog">
      <h2>Upload File List</h2>

      <div v-if="uploadStore.uploadList.length > 0" class="upload-dialog-progress-bar-container">
        <div class="progress-bar-info">
          <div class="upload-files">
            <b>{{ uploadStore.uploadList.length }}</b> file{{ uploadStore.uploadList.length == 1 ? "" : "s" }} in total
          </div>

          <div class="upload-info">
            <span class="upload-file-size-info">
              {{ formatSizeFromBytesWithoutUnits(uploadedSize, totalUploadSize) }}/<b>{{
                formatSizeFromBytes(totalUploadSize)
              }}</b>
            </span>
            <span class="upload-time-left-info"> Time left: {{ timeLeft.total }} </span>
          </div>
        </div>
        <v-progress-linear :model-value="uploadStore.uploadProgress" height="12"></v-progress-linear>
      </div>

      <div v-if="!uploadStore.uploadList.length">
        <div class="text-center">No files</div>
      </div>
      <div v-else class="upload-items">
        <div class="upload-items-header">
          <span class="upload-item-status">STATUS</span>
          <span class="upload-item-speed">SPEED</span>
          <span class="upload-item-time-left">TIME LEFT</span>
          <span class="upload-item-type">TYPE</span>
          <span class="upload-item-origin">ORIGIN</span>
          <span class="upload-item-file-name">FILE NAME</span>
          <span class="upload-item-size">SIZE</span>
          <span class="upload-item-trash-icon"></span>
        </div>
        <div class="upload-item" v-for="(upload_item, i) in uploadStore.uploadList">
          <span class="upload-item-status" :title="upload_item.errors[0]">
            <v-progress-circular
              v-if="
                upload_item.upload_progress !== null && upload_item.upload_progress < 100 && !upload_item.errors.length
              "
              :model-value="upload_item.upload_progress"
              :indeterminate="upload_item.upload_progress === 0"
            ></v-progress-circular>
            <div
              v-else
              :class="{
                success: upload_item.upload_progress === 100 && upload_item.errors.length === 0,
                error: upload_item.errors.length > 0,
                pending: upload_item.upload_progress == null,
              }"
            ></div>
          </span>
          <span class="upload-item-speed">{{
            upload_item.upload_speed ? formatSizeFromBytes(upload_item.upload_speed, true) + "/s" : "-"
          }}</span>
          <span class="upload-item-time-left">{{ timeLeft.items[i] }}</span>
          <span
            class="upload-item-type"
            :class="{
              image: getFileTypeFromFileName(upload_item.file.name) === 'image',
              video: getFileTypeFromFileName(upload_item.file.name) === 'video',
              text: getFileTypeFromFileName(upload_item.file.name) === 'srt',
            }"
          ></span>
          <span class="upload-item-origin">{{ upload_item.origin.str }}</span>
          <span class="upload-item-file-name">{{ upload_item.file.name }}</span>
          <span class="upload-item-size">{{ formatSizeFromBytes(upload_item.file.size) }}</span>
          <delete-button id="btnAbortUploadUpload" @on_click="openConfirmAbortDialog(upload_item)" />
        </div>
      </div>

      <div class="dialog-button-container">
        <div class="close-button-parent">
          <v-btn
            @click="close"
            class="small-button small-button--no-icon close-dialog-button"
            :ripple="false"
            variant="flat"
            id="btnCloseUploadFileList"
            >Close</v-btn
          >
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<style scoped>
.upload-dialog:deep(.v-progress-linear__determinate) {
  border-radius: 6px;
  background: #d2a7ef !important;
}

.upload-dialog:deep(.v-progress-linear__background) {
  border-radius: 6px;
}

/* ylorenz : hide footer in CSS rather than empty slot bottom cause JS error */
:deep(.v-data-table-footer) {
  display: none;
}

.abort-file-dialog {
  display: flex;
  width: 624px;
  padding: 48px !important;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 24px !important;
  background: #fff;
  box-shadow: none !important;
}

.abort-file-dialog h1 {
  color: #141414;
  align-self: stretch;
  font-family: Manrope;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px;
}

.delete-file-dialog h1 {
  color: #141414;
  align-self: stretch;
  font-family: Manrope;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px;
}

.upload-dialog {
  display: flex;
  width: 1062px;
  padding: 48px;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  border-radius: 24px !important;
  background: #fff;
  box-shadow: none !important;
}

.upload-dialog:deep(.v-card__underlay) {
  display: none !important;
}

.upload-dialog h2 {
  color: #141414;

  font-family: Manrope;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px;
}

.upload-dialog-progress-bar-container {
  width: 100%;
  flex-direction: column;
}

.progress-bar-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.upload-files {
  width: 160px;
  color: #141414;
  font-family: Manrope;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.upload-info {
  width: fit-content;
  display: flex;
}

.upload-file-size-info,
.upload-time-left-info {
  display: flex;
  padding: 0px 12px;
  align-items: center;
  width: fit-content !important;
  align-self: stretch;

  color: #141414;
  text-align: right;
  font-family: Manrope;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.upload-file-size-info {
  border-right: 1px solid #d0d0d0;
}

.upload-dialog-progress-bar-container > .v-progress-linear {
  width: 100%;
  margin-top: 16px;
}

.upload-items {
  display: flex;
  width: 100%;
  flex-direction: column;
  overflow-y: scroll;
  max-height: 480px;
}

.upload-items::-webkit-scrollbar {
  width: 6px;
}

.upload-items::-webkit-scrollbar-thumb {
  background: #b9b9b9;
  border-radius: 18px;
}

.upload-item,
.upload-items-header {
  display: flex;
  width: 100%;
  padding: 16px 0px;
  align-items: center;
  gap: 32px;
  align-self: stretch;
  border-bottom: 1px solid #e8e8e8;
  flex: 1;
  font-family: Manrope;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.upload-items-header {
  color: #5b5b5b;
}

.upload-item {
  color: #141414;
}

.upload-item-status,
.upload-item-type {
  width: 52px;
}

.upload-item-speed,
.upload-item-size {
  width: 80px;
}

.upload-item-time-left {
  width: 72px;
}

.upload-item-trash-icon {
  width: 40px;
}

.upload-item-origin,
.upload-item-file-name {
  width: 191px;
}

.upload-item-type.image {
  height: 20px;
  background: url("@/assets/icon-image.svg") no-repeat;
  background-position-x: 4px;
  background-position-y: 50%;
}

.upload-item-type.video {
  height: 20px;
  background: url("@/assets/icon-recording.svg") no-repeat;
  background-position-x: 4px;
  background-position-y: 50%;
}

.upload-item-type.text {
  height: 20px;
  background: url("@/assets/icon-text-file.svg") no-repeat;
  background-position-x: 4px;
  background-position-y: 50%;
}

.upload-item-status:deep(.v-progress-circular) {
  margin-left: 8px;
}

.upload-item-status:deep(.v-progress-circular__overlay) {
  stroke: #d2a7ef;
  stroke-linecap: round;
}

.upload-item-status:deep(.v-progress-circular__underlay) {
  stroke: #e8e8e8;
}

.upload-item-status > .success {
  height: 32px;
  background: url("@/assets/icon-success.svg") no-repeat;
  background-position: center;
  background-size: 32px 32px;
}

.upload-item-status > .error {
  height: 32px;
  background: url("@/assets/icon-error.svg") no-repeat;
  background-position: center;
  background-size: 32px 32px;
}

.upload-item-status > .pending {
  height: 32px;
  background: url("@/assets/icon-pending.svg") no-repeat;
  background-position: center;
  background-size: 24px 24px;
}

.upload-dialog * .close-dialog-button {
  background: #6d4bf2 !important;
  color: #fff !important;
  margin-left: 24px;
}

.close-dialog-button {
  border: 1px solid #6d4bf2;
  background: #fff;

  color: #6d4bf2 !important;
}

.close-dialog-button:hover {
  background: #6d4bf2;

  color: #fff !important  ;
}

.dialog-button-container {
  display: flex;
  flex-direction: row;
  height: 52px;
  justify-content: flex-end;
  margin-top: 48px;
  width: 100%;
}

.close-button-parent {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  align-content: center;
}

.upload-dialog > .dialog-button-container {
  margin-top: 0px;
}

.abort-file-dialog > .dialog-button-container {
  gap: 24px;
}
</style>
