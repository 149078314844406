import { Storage } from "aws-amplify";
import {
  S3Client,
  HeadObjectCommand,
  ListObjectsV2Command,
  CreateMultipartUploadCommand,
  UploadPartCommand,
  CompleteMultipartUploadCommand,
  ListMultipartUploadsCommand,
  ListPartsCommand,
  PutObjectCommand,
} from "@aws-sdk/client-s3";
import * as Sentry from "@sentry/vue";

import { FetchHttpHandler } from "@smithy/fetch-http-handler";
import { Auth } from "aws-amplify";
import PQueue from "p-queue";

import { useUserStore } from "@/stores/userStore";
import { memoize } from "@/utils/memoize";
import { getFileNameFromPath } from "@/utils/file";

Storage.configure({ level: "private" });
let abort_controller = null;

async function s3Put(key, file, config = {}) {
  return new Promise(async (resolve, reject) => {
    const userStore = useUserStore();
    let credentials = Auth.currentCredentials;

    config.hasOwnProperty("progressCallback") && config.progressCallback({ loaded: 0, total: file.size });

    const abortListener = ({ target }) => {
      abort_controller.signal.removeEventListener("abort", abortListener);
      reject(target.reason);
    };
    abort_controller.signal.addEventListener("abort", abortListener);

    const client = new S3Client({
      region: import.meta.env.VITE_APP_REGION,
      credentials: credentials,
      requestHandler: new FetchHttpHandler({ keepAlive: false }),
    });

    const put_object_input = {
      Bucket: import.meta.env.VITE_APP_SUPPLIER_BUCKET,
      Key: `private/${userStore.user.impersonate.username}/${key}`,
      Metadata: { json: JSON.stringify(file.meta ?? config.meta) },
      Body: file,
    };

    const put_object_command = new PutObjectCommand(put_object_input);
    const put_object_response = await client.send(put_object_command, { abortSignal: abort_controller.signal });
    config.hasOwnProperty("progressCallback") && config.progressCallback({ loaded: file.size, total: file.size });
    console.log("uploaded", put_object_response);

    abort_controller.signal.removeEventListener("abort", abortListener);
    resolve({ key });
  });
}

async function promisePut(key, file, config) {
  //console.log("promisePut", key, config);

  const userStore = useUserStore();
  config.identityId = userStore.user.impersonate.username;

  if (config.resumable) {
    return new Promise((resolve, reject) => {
      Storage.put(key, file, {
        ...config,
        completeCallback: (event) => {
          resolve(event);
        },
        errorCallback: (err) => {
          reject(err);
        },
      });
    });
  } else {
    return Storage.put(key, file, config);
  }
}

async function s3List(key, config = { pageSize: "ALL" }) {
  // console.log("s3List", config, key);

  const userStore = useUserStore();

  //config.level = "private";
  config.identityId = userStore.user.impersonate.username;

  return Storage.list(key, config);
}

async function s3Get(key, config = {}) {
  //console.log("s3Get", key, config);

  const userStore = useUserStore();

  config.identityId = userStore.user.impersonate.username;
  //config.level = "private";

  return Storage.get(key, config);
}

async function s3Remove(key, config = {}) {
  // console.log("s3Remove", config);

  const userStore = useUserStore();

  config.identityId = userStore.user.impersonate.username;
  //config.level = "private";

  return Storage.remove(key, config);
}

async function s3Cancel(upload_promise, key, config = {}) {
  // console.log("s3Cancel", upload_promise);
  abort_controller?.abort("User cancelled upload");
  if (getFileNameFromPath(key).startsWith("GX")) {
    const userStore = useUserStore();
    config.identityId = userStore.user.impersonate.username;
    await Storage.remove(key.slice(0, -3) + "gpmf", config);
  }
  return Storage.cancel(upload_promise);
}

function calculateNumberOfChunks(file_size, chunk_size) {
  return Math.ceil(file_size / chunk_size);
}

function filterNotUploadedParts(available_parts, uploaded_parts) {
  let uploaded_parts_num = uploaded_parts.map((part) => part.PartNumber - 1);
  return available_parts.filter((part_num) => !uploaded_parts_num.includes(part_num));
}

async function createMultipartUpload(client, multipart_upload_input, available_parts, abort_signal) {
  const create_upload_command = new CreateMultipartUploadCommand(multipart_upload_input);
  const create_upload_response = await client.send(create_upload_command, { abortSignal: abort_signal });

  let upload_part_responses = [];
  let upload_id = create_upload_response.UploadId;
  return { upload_part_responses, parts_to_upload: available_parts, upload_id };
}

async function resumeMultipartUpload(
  client,
  list_uploads_response,
  multipart_upload_input,
  available_parts,
  abort_signal
) {
  let upload_id = list_uploads_response.Uploads[0].UploadId;

  const list_parts_input = {
    Bucket: multipart_upload_input.Bucket,
    Key: multipart_upload_input.Key,
    UploadId: upload_id,
    MaxParts: 1000,
  };
  const list_parts_command = new ListPartsCommand(list_parts_input);
  const list_parts_response = await client.send(list_parts_command, { abortSignal: abort_signal });

  let upload_part_responses = list_parts_response.Parts ?? [];
  let parts_to_upload = filterNotUploadedParts(available_parts, upload_part_responses);
  return { upload_part_responses, parts_to_upload, upload_id };
}

async function initMultipartUpload(client, multipart_upload_input, available_parts, abort_signal) {
  const list_uploads_command = new ListMultipartUploadsCommand({
    Bucket: multipart_upload_input.Bucket,
    Prefix: multipart_upload_input.Key,
  });
  const list_uploads_response = await client.send(list_uploads_command);

  if (list_uploads_response?.Uploads?.length) {
    return resumeMultipartUpload(client, list_uploads_response, multipart_upload_input, available_parts, abort_signal);
  } else {
    return createMultipartUpload(client, multipart_upload_input, available_parts, abort_signal);
  }
}

async function multipartUpload(key, file, config) {
  const chunk_size = 1024 * 1024 * 5; // 5MB in bytes
  const max_retry_attempts = 20;
  const backoff_delay = 3000; // 3 seconds
  const concurrency_limit = 5;

  function clearMultipartUploadQueue(queue) {
    queue.pause();
    queue.clear();
  }

  return new Promise(async (resolve, reject) => {
    let credentials = Auth.currentCredentials;
    const userStore = useUserStore();
    const queue = new PQueue({ concurrency: concurrency_limit });
    const abortListener = ({ target }) => {
      abort_controller.signal.removeEventListener("abort", abortListener);
      clearMultipartUploadQueue(queue);
      reject(target.reason);
    };
    abort_controller.signal.addEventListener("abort", abortListener);

    const client = new S3Client({
      region: import.meta.env.VITE_APP_REGION,
      credentials: credentials,
      useAccelerateEndpoint: true,
      requestHandler: new FetchHttpHandler({ keepAlive: false }),
    });

    const multipart_upload_input = {
      Bucket: import.meta.env.VITE_APP_SUPPLIER_BUCKET,
      Key: `private/${userStore.user.impersonate.username}/${key}`,
      Metadata: { json: JSON.stringify(file.meta) },
    };

    const number_of_chunks = calculateNumberOfChunks(file.size, chunk_size);
    let available_parts = [...Array(number_of_chunks).keys()];

    // check if multipart upload has already started
    let { upload_part_responses, parts_to_upload, upload_id } = await initMultipartUpload(
      client,
      multipart_upload_input,
      available_parts,
      abort_controller.signal
    );

    if (abort_controller.signal.aborted) {
      clearMultipartUploadQueue(queue);
      return;
    }

    let finished_parts = available_parts.length - parts_to_upload.length;
    config.progressCallback({ loaded: Math.min(finished_parts * chunk_size, file.size), total: file.size });
    let failed_part_retry_attempts = {};

    const constantBackoff = () => {
      return new Promise((resolve) => setTimeout(resolve, backoff_delay));
    };

    const uploadPartWithRetry = async (part_num, attempt = 0) => {
      console.log("uploadPartWithRetry", part_num, attempt);
      if (abort_controller.signal.aborted) {
        clearMultipartUploadQueue(queue);
        return;
      }

      let byte_start = part_num * chunk_size;
      let byte_end = Math.min((part_num + 1) * chunk_size, file.size);

      const upload_params = {
        ...multipart_upload_input,
        UploadId: upload_id,
        PartNumber: part_num + 1,
        Body: file.slice(byte_start, byte_end),
      };
      const upload_part_command = new UploadPartCommand(upload_params);

      try {
        // attempt to make the upload of the current part
        const upload_part_response = await client.send(upload_part_command, { abortSignal: abort_controller.signal });
        upload_part_responses.push({ ...upload_part_response, PartNumber: part_num + 1 });
        finished_parts++;
        parts_to_upload = parts_to_upload.filter((i) => i !== part_num);
        config.progressCallback({ loaded: Math.min(finished_parts * chunk_size, file.size), total: file.size });
      } catch (err) {
        // in case of failures, we are going to keep track of the number of failed attempts.
        // if the number of failed attempts for a single part is over 20, we abort the upload of that part.
        // otherwise we perform a retry of the upload using a constant 3s backoff strategy

        console.log(`Error uploading part ${part_num}:`, err);
        failed_part_retry_attempts[part_num] = (failed_part_retry_attempts?.[part_num] || 0) + 1;

        if (failed_part_retry_attempts[part_num] === max_retry_attempts) {
          abort_controller.abort(`Upload of part number ${part_num} failed too many times.`);
          clearMultipartUploadQueue(queue);
          reject(`Upload of part number ${part_num} failed too many times.`);
          return;
        }
        console.log(
          `Retrying upload of part number ${part_num}. Attempt number ${failed_part_retry_attempts[part_num]}.`
        );
        await constantBackoff();
        await uploadPartWithRetry(part_num, failed_part_retry_attempts[part_num]);
      }
    };

    for (let part_num of parts_to_upload) {
      await queue.add(() => uploadPartWithRetry(part_num, failed_part_retry_attempts[part_num]));
    }

    await queue.onIdle();

    if (abort_controller.signal.aborted) {
      clearMultipartUploadQueue(queue);
      return;
    }

    upload_part_responses.sort((a, b) => a.PartNumber - b.PartNumber);

    const complete_command = new CompleteMultipartUploadCommand({
      ...multipart_upload_input,
      UploadId: upload_id,
      MultipartUpload: {
        Parts: upload_part_responses.map(({ ETag, PartNumber }) => ({
          ETag,
          PartNumber,
        })),
      },
    });

    try {
      const complete_response = await client.send(complete_command, { abortSignal: abort_controller.signal });
      console.log("finished", complete_response);

      abort_controller.signal.removeEventListener("abort", abortListener);
      resolve({ key });
    } catch (err) {
      clearMultipartUploadQueue(queue);
      reject(err);
    }
  });
}
async function uploadAsset(upload_file, mime_type = "auto", resumable = true) {
  //console.log("uploadAsset", upload_file);
  abort_controller = new AbortController();
  upload_file.upload_progress = 0;

  /*
  upload_file.progresses.push({
    date: new Date(),
    progress: {
      loaded: 0,
      total: null,
    },
  });
  */

  const upload_config = {
    progressCallback: (progress) => {
      //console.log("progressCallback", progress);
      upload_file.progresses.push({
        date: new Date(),
        progress: progress,
      });

      // Keep maximum 10
      upload_file.progresses = upload_file.progresses.slice(-10);

      let first = upload_file.progresses[0];
      let last = upload_file.progresses[upload_file.progresses.length - 1];

      let diff_date_in_seconds = (last.date.getTime() - first.date.getTime()) / 1000;
      let diff_loaded = last.progress.loaded - first.progress.loaded;

      let speed = null;
      if (diff_date_in_seconds) {
        speed = diff_loaded / diff_date_in_seconds;
      }
      //console.log("progressCallback speed bytes / s", speed, formatSizeFromBytes(speed));

      //upload_file.progresses.map((p) => {});
      upload_file.upload_speed = speed;
      upload_file.upload_progress = (progress.loaded / progress.total) * 100;
    },
    useAccelerateEndpoint: true,
    contentType: mime_type === "auto" ? upload_file.type : null,
    metadata: { json: JSON.stringify(upload_file.file.meta) },
    resumable,
    level: "private", // We need that to prevent "[ERROR] AWSS3UploadTask - error completing upload Error: File size does not match between local file and file on s3"
  };

  let size_50mb = 1024 * 1024 * 50;
  if (upload_file.file.size > size_50mb) {
    // use multipart upload only for larger files
    upload_file.upload_promise = multipartUpload(
      `${upload_file.directory}${upload_file.file.name}`,
      upload_file.file,
      upload_config
    );
  } else {
    upload_file.upload_promise = s3Put(
      `${upload_file.directory}${upload_file.file.name}`,
      upload_file.file,
      upload_config
    );
  }

  return upload_file.upload_promise
    .catch((err) => {
      upload_file.errors.push(err);
      if (!err?.message?.startsWith("File size does not match")) {
        console.log(`Upload error: ${err}`);
      }
    })
    .finally(() => {
      upload_file.upload_promise = "done";
    });
}

// inspiration : https://github.com/koresar/s3-ls/blob/master/index.js
async function listFolders(prefix) {
  console.log("listFolders:", prefix);

  const client = new S3Client({
    region: import.meta.env.VITE_APP_REGION,
    credentials: Auth.currentCredentials,
  });

  const input = {
    Region: import.meta.env.VITE_APP_SUPPLIER_BUCKET_REGION,
    Bucket: import.meta.env.VITE_APP_SUPPLIER_BUCKET,
    Delimiter: "/",
    Prefix: prefix,
  };
  //console.log("input:", input);

  // https://docs.aws.amazon.com/AWSJavaScriptSDK/v3/latest/clients/client-s3/classes/listobjectsv2command.html
  const command = new ListObjectsV2Command(input);
  // https://docs.aws.amazon.com/AWSJavaScriptSDK/v3/latest/clients/client-s3/modules/listobjectsoutput.html
  const response = await client.send(command);
  console.log("response:", response);

  return response.CommonPrefixes || [];
}

async function fetchMetadata(key) {
  const client = new S3Client({
    region: import.meta.env.VITE_APP_REGION,
    credentials: Auth.currentCredentials,
  });

  const input = {
    Region: import.meta.env.VITE_APP_SUPPLIER_BUCKET_REGION,
    Bucket: import.meta.env.VITE_APP_SUPPLIER_BUCKET,
    Key: key,
  };

  const command = new HeadObjectCommand(input);
  const response = await client.send(command);

  if (!response.Metadata.json) {
    Sentry.captureMessage(`No metadata for key ${key}`);
    return {};
  }

  return JSON.parse(response.Metadata.json);
}

const fetchMetadataMemoized = memoize(fetchMetadata);

// ylorenz todo code improvement : use it in delete functions
// function deleteFilesInDirectories() {}

export {
  promisePut,
  s3List,
  s3Get,
  s3Remove,
  s3Cancel,
  uploadAsset,
  fetchMetadata,
  fetchMetadataMemoized,
  listFolders,
};
