import { defineStore } from "pinia";
import { ref } from "vue";
import UploadFile from "@/classes/UploadFile";
import EventBus from "@/classes/EventBus";

export const useUploadStore = defineStore(
  "upload",
  () => {
    const uploadList = ref([]);
    const uploadStatus = ref("stopped");
    const uploadProgress = ref(0);

    function addFilesToUploadStore(files, wrappedObject, subtype) {
      let start_upload = false;
      for (const [idx, file] of Object.entries(files)) {
        const uploadFile = UploadFile.from_file(file, wrappedObject, subtype);
        //console.log("uploadFile", uploadFile);

        const found_in_upload_list = uploadList.value.find(
          (file_in_upload_list) =>
            file_in_upload_list.directory === uploadFile.directory &&
            file_in_upload_list.file.name === uploadFile.file.name
        );

        if (!found_in_upload_list) {
          start_upload = true;
          uploadList.value.push(uploadFile);
        }
      }

      if (start_upload) {
        //console.log("uploadList : push", uploadFile.file.name);
        EventBus.$emit("UploadFileManager-upload-start");
      } else {
        //console.log("uploadList : skip", uploadFile.file.name);
        EventBus.$emit("UploadFileManager-upload-show");
      }
    }

    return { uploadList, uploadStatus, uploadProgress, addFilesToUploadStore };
  },
  { persist: false } // File from v-input can't be serialize easilly
);
