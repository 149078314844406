<template>
  <v-app>
    <v-main>
      <v-container class="fill-height" fluid>
        <v-row align="center" justify="center">
          <v-col align="center" cols="12" sm="12" md="12">Not Found</v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>
