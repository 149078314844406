const spaces = [
  {
    title: "Supply",
    key: "supply",
    route: "supply-project-list",
    prefix: "supply-",
    appName: "DeepScenario Supply",
  },
  {
    title: "Explore",
    key: "explore",
    route: "explore-release-list",
    prefix: "explore-",
    appName: "DeepScenario Explore",
  },
  {
    title: "Admin",
    key: "admin",
    route: "admin-user-list",
    prefix: "admin-",
    appName: "DeepScenario Admin",
  },
];

function getCurrentSpaceWithRoute(route) {
  return spaces.find((space) => route.name.startsWith(space.prefix));
}

export { spaces, getCurrentSpaceWithRoute };
