function memoize(fn) {
  let cache = {};
  return async (arg) => {
    if (arg in cache) {
      console.log("Fetching from memoized cache");
      return cache[arg];
    } else {
      console.log("Memoizing");
      let result = await fn(arg);
      cache[arg] = result;
      return result;
    }
  };
}

export { memoize };
