<script setup>
import { onMounted, watch, ref, onUnmounted, onUpdated } from "vue";
import { useRouter, useRoute } from "vue-router";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);

import { Auth } from "aws-amplify";

import { getCurrentSpaceWithRoute } from "@/utils/space";

import "@aws-amplify/ui-vue/styles.css";
import SnackBar from "@/components/SnackBar";
import AwsFileBrowser from "@/components/AwsFileBrowser";
import UploadFileManager from "@/components/UploadFileManager";

import { useUserStore } from "@/stores/userStore";
import { goToRoute } from "@/utils/nav";
import { PrivacyPolicyRelease } from "./utils/privacy_policy";

const userStore = useUserStore();
const router = useRouter();
const route = useRoute();

const open_privacy_dialog = ref(false);
const interval_retrieve_session = ref(undefined);
const interval_privacy_policy = ref(undefined);

const privacy_policy = new PrivacyPolicyRelease();

// This is done in order to allow mocking with Cypress (see cypress/e2e/testPrivacyPolicy.cy.js)
window.privacyPolicyRelease = privacy_policy;

watch(route, () => {
  const currentSpace = getCurrentSpaceWithRoute(route);
  if (currentSpace) {
    document.title = currentSpace.appName;
    // or append only the space title ?
    // document.title = "DeepScenario App - " + currentSpace.title;
    checkForPrivacyPolicy();
  } else {
    document.title = "DeepScenario App"; // login page
  }
});

// only for pinia BUGFIX (ylorenz todo delete later)
onMounted(() => {
  // Double point !!!!! see pinia.js in git log
  var storageContext = localStorage.getItem("deepscenario.supplierapp..context");

  if (storageContext) {
    localStorage.clear();
  }

  Auth.currentAuthenticatedUser()
    .then(async (response) => {
      // console.log("Auth.currentAuthenticatedUser then", response);
      userStore.retrieveCurrentSession();
    })
    .catch((error) => {
      //console.log("Auth.currentAuthenticatedUser catch", error); // error = "The user is not authenticated"
      userStore.$reset();
      router.push({ name: "login" });
    });

  interval_retrieve_session.value = setInterval(() => {
    userStore.retrieveCurrentSession();
  }, 1000 * 60 * 5); // refresh token every 5 minutes

  // we do the first check as soon as the user is in
  checkForPrivacyPolicy();

  // and then every hour we check again
  interval_privacy_policy.value = setInterval(() => {
    checkForPrivacyPolicy();
  }, 1000 * 60 * 60 * 1);
});

function checkForPrivacyPolicy() {
  // first we need to make sure the user is logged in
  Auth.currentAuthenticatedUser()
    .then(async (response) => {
      const last_accepted_policy = await userStore.getLastAcceptedPrivacyPolicy();
      open_privacy_dialog.value = !privacy_policy.hasUserAcceptedLatestPrivacyPolicy(last_accepted_policy);
    })
    .catch((error) => {
      //console.log("Auth.currentAuthenticatedUser catch", error); // error = "The user is not authenticated"
      userStore.$reset();
      router.push({ name: "login" });
    });
}

onUnmounted(() => {
  clearInterval(interval_retrieve_session.value);
  clearInterval(interval_privacy_policy.value);
});

function acceptPrivacyPolicy() {
  const date_now = dayjs().utc();
  userStore.updateLastAcceptedPrivacyPolicy(date_now);
  open_privacy_dialog.value = false;
}

function closeAndSignout() {
  Auth.signOut()
    .then((data) => {
      userStore.$reset();
      goToRoute({
        name: "login",
      });
    })
    .catch((err) => {
      console.log("Auth.signOut err", err);
    });
  open_privacy_dialog.value = false;
}
</script>

<template>
  <v-app>
    <v-dialog persistent v-model="open_privacy_dialog" width="auto" transition="dialog-bottom-transition">
      <div class="policy-dialog-container">
        <h1>Update to our Privacy Policy</h1>
        <p>
          As we continue to improve our products and services, we have updated our
          <a href="https://www.deepscenario.com/data-privacy" target="_blank">Privacy Policy</a>. By continuing with the
          sign-in, you agree to the latest version.
        </p>
        <div class="dialog-button-container">
          <v-btn
            id="close-privacy-policy-button"
            @click="closeAndSignout"
            class="small-button small-button--no-icon close-dialog-button"
            :ripple="false"
            variant="flat"
          >
            Exit
          </v-btn>
          <v-btn
            id="accept-privacy-policy-button"
            color="ds-primary-violet"
            @click="acceptPrivacyPolicy"
            class="small-button small-button--no-icon policy-dialog-button"
            :ripple="false"
            variant="flat"
          >
            Continue
          </v-btn>
        </div>
      </div>
    </v-dialog>

    <SnackBar />
    <AwsFileBrowser />
    <UploadFileManager />

    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<style>
html,
body {
  font-family: Manrope;
}

#nprogress .bar {
  background: #6d4bf2 !important;
}

.v-container {
  @media screen and (max-width: 1280px) {
    padding-left: 48px !important;
    padding-right: 48px !important;
  }

  @media screen and (min-width: 1280px) and (max-width: 1920px) {
    padding-left: 28px !important;
    padding-right: 28px !important;
  }
}

.big-button {
  display: flex;
  padding: 16px 24px 16px 54px;
  justify-content: center;
  align-items: center;
  gap: 16px;
  border-radius: 40px;
  width: fit-content;
  color: #fff !important;
  font-family: Manrope;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 2.8px;
  line-height: 20px;
  text-transform: uppercase;
  height: 56px !important;
  box-shadow: none;
}

.small-button {
  display: flex;
  padding: 16px 24px 16px 52px;
  justify-content: center;
  align-items: center;
  gap: 16px;
  border-radius: 40px;
  width: fit-content;
  color: #fff !important;
  font-family: Manrope;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 2.4px;
  line-height: 20px;
  text-transform: uppercase;
  height: 44px !important;
  box-shadow: none;
}

.small-button--no-icon {
  padding-left: 24px !important;
}

.policy-dialog-container {
  display: flex;
  width: 800px;
  padding: 48px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 24px;
  background: #fff;
}

.policy-dialog-container h1 {
  color: #141414;
  font-family: Manrope;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 48px;
  margin-bottom: 16px;
}

.policy-dialog-container p {
  color: #5b5b5b;
  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  margin-bottom: 1.25rem;
}

.policy-iframe {
  width: 100%;
  height: 400px;
  border: none;
  margin-bottom: 1.25rem;
}
.dialog-checkbox-container {
  display: flex;
  align-items: center;
  margin-bottom: 1.25rem;
}

.checkbox {
  color: #573cc2;
}

.policy-dialog-button {
  background: #6d4bf2 !important;
  color: #fff !important;
  margin-left: 12px;
}

.policy-dialog-button:hover {
  background: #573cc2 !important;
}

.dialog-button-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  margin-top: 1.25rem !important;
}

.close-dialog-button {
  border: 1px solid #6d4bf2;
  background: #fff;

  color: #6d4bf2 !important;
}

.close-dialog-button:hover {
  background: #6d4bf2;

  color: #fff !important  ;
}
</style>
